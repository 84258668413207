import React, { Component } from 'react';
import { Button, TextField, Form } from 'factor';
import './styles.scss';

export const emptyValidation = {
  func: (value, input, rule) => {
    return !!(value && value.trim());
  },
  error: (name, rule) => {
    return `${rule.title} must be filled!`;
  },
  title: 'Password',
};

export const emailValidation = {
  func: (value) => {
    return EMAIL_REG.test(value);
  },
  error: (name, rule) => {
    return `${rule.title} must be a valid email address!`
  },
  title: 'Email',
};

export const EMAIL_REG = /(.+)@(.+){2,}\.(.+){2,}/;

export class FormExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      email: '',
      valid: {
        password: false,
        email: false,
      },
      isFormValid: false,
    };
  }

  isFormValid(isValid, name) {
    this.setState((state) => {
      return {
        ...state,
        valid: {
          ...state.valid,
          [name]: isValid,
        },
      };
    }, () => {
      const { valid } = this.state;
      if (valid.password && valid.email) {
        this.setState({
          isFormValid: true,
        });
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const emailValid = this.refEmail.current.validateControl();
    const passValid = this.refPassword.current.validateControl();

    if (emailValid && passValid) {
      console.log(`Email: ${this.state.email}, Password: ${this.state.password}`);
    }
  };

  refEmail = React.createRef();
  refPassword = React.createRef();

  render() {
    return (
      <Form title="Sign in" className="d-flex flex-column align-items-center mt-5 mb-5 login-form"
            onSubmit={this.onSubmit}
      >
        <TextField
          ref={this.refEmail}
          label="Email"
          name="email"
          type="text"
          validationRules={[
            emptyValidation,
            emailValidation,
          ]}
          onChange={(v) => this.setState({
            email: v,
          })}
          onValidate={(valid) => this.isFormValid(valid, 'email')}
          value={this.state.email}
        />
        <TextField
          ref={this.refPassword}
          label="Password"
          name="form_password"
          type="password"
          validationRules={[
            emptyValidation,
          ]}
          onChange={(v) => this.setState({
            password: v,
          })}
          onValidate={(valid) => this.isFormValid(valid, 'password')}
          value={this.state.password}
        />
        <Button
          className="btn-square _shamrock"
        >
          Log in
        </Button>
      </Form>
    );
  }
}
