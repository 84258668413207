let initialState = {
  isComponentsLayout: true,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LAYOUT_CHANGE':
      return {
        ...state,
        isComponentsLayout: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
