import React, { Component } from 'react';
import { Switch, ButtonsGroup } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import './switchPage/switchPage.scss';
import { PagePartial } from '../demoComponents/PagePartial';

export class SwitchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      includeValue: false,
      exampleValue: true,
      btnSize: null,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example', },
    { title: 'React usage', link: '#react', },
  ];

  list1 = ['By date range', 'By campaign'];
  list2 = ['Short', 'Very very very long'];

  sizes = (function() {
    const sizes = [
      { title: 'Default', value: null },
      { title: 'Medium', value: 'md' },
      { title: 'Large', value: 'lg' },
    ];
    return sizes;
  })();

  renderSizeToggle() {
    return (
      <div className="col-6 d-flex justify-content-end">
        <ButtonsGroup
          value={this.state.btnSize}
          items={this.sizes}
          onChange={(v) => this.setState({
            btnSize: v,
          })}
        />
      </div>
    );
  }

  render() {
    const {
      includeValue,
      exampleValue,
      btnSize,
    } = this.state;

    const switch1 = <Switch
      size={btnSize}
      list={this.list1}
      onChange={(value) => this.setState({
        includeValue: value,
      })}
      value={includeValue}
    />;

    const switch2 = <Switch
      size={btnSize}
      list={this.list2}
      onChange={(value) => this.setState({
        exampleValue: value,
      })}
      value={exampleValue}
    />;

    return (
      <PagePartial
        title={'Switch'}
        className="switch-page"
        navigation={this.pageNavigation}
      >
        <div className="row mb-2 mt-2 align-items-center">
          <h5 id="example" className="col">Example</h5>
          {this.renderSizeToggle()}
        </div>
        <div className="example row">
          <div className="col-6">
            {switch1}
          </div>
          <div className="col-6">
            {switch2}
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this switch with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                  import { Switch } from 'factor';
                  import 'factor/src/scss/_switch.scss';

                  class YourPage extends React.Component {
                    render() {
                      const {
                        switchValue,
                      } = this.state;

                      return (
                        <Switch
                          list={['Value 1', 'Value 2']}
                          onChange={(v) => this.setState({
                            switchValue: v,
                          })}
                          value={switchValue}
                        />
                      );
                    }
                  }
                  `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="required">onChange</td>
                <td>function</td>
                <td>none</td>
                <td>Callback when value changed</td>
              </tr>
              <tr>
                <td className="required">list</td>
                <td>array of 2 string</td>
                <td>none</td>
                <td>Switch labels</td>
              </tr>
              <tr>
                <td className="required">value</td>
                <td>boolean</td>
                <td>false</td>
                <td>Switch value</td>
              </tr>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Switch wrapper class name</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
