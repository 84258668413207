import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Tabs } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

const itemNames = {
  All: 'All',
  Products: 'Products',
  Plugins: 'Plugins'
};

export class TabsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: this.tabsItems[0].value,
    }
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  tabsItems = [itemNames.All, itemNames.Plugins, itemNames.Products]
    .map(item => ({
      title: item,
      value: item,
    }));

  render() {
    const {
      currentTab,
    } = this.state;

    let content;

    switch (currentTab) {
      case itemNames.All: {
        content = <p>I'm content {itemNames.All}</p>
        break;
      }
      case itemNames.Products: {
        content = <p>I'm content {itemNames.Products}</p>
        break;
      }
      case itemNames.Plugins: {
        content = <p>I'm content {itemNames.Plugins}</p>
        break;
      }
      default: {
        content = null;
      }
    }
    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Tabs"
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-12">
            <Tabs items={this.tabsItems} onChange={(item) => this.setState({
              currentTab: item.value
            })} value={currentTab} />
            <div className="content">
              {content}
            </div>
          </div>
        </div>
        <br />
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Tabs with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Tabs } from 'factor';
                import 'factor/src/scss/_tabs.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      currentTab: 'All',
                    }
                  }

                  tabsItems = ['All', 'Plugins', 'Products']
                      .map(item => ({
                        title: item,
                        value: item,
                      }));

                  render() {
                    const {
                      currentTab,
                    } = this.state;

                    return (
                      <Tabs
                        items={this.tabsItems}
                        onChange={(item) => this.setState({
                          currentTab: item.value
                        })}
                        value={currentTab}
                      />
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Additional class</td>
              </tr>
              <tr>
                <td>value</td>
                <td>any</td>
                <td>none</td>
                <td>Current selected tab</td>
              </tr>
              <tr>
                <td>items</td>
                <td>array of objects <br />
                  <code>
                    {'{'}<br />
                  title: string, <br />
                  value: any, <br />
                    {'}'}
                  </code>
                </td>
                <td>none</td>
                <td>Array of tabs</td>
              </tr>
              <tr>
                <td>onChange</td>
                <td>function(item)</td>
                <td>none</td>
                <td>Change callback</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
