import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonCircle, Card, CardHoveredContent, Snackbar } from 'factor';

const HEX_REG = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/;

const COPY_TITLE = 'Copy';
const COPIED_TITLE = 'Copied!';

function hexToRgb(hex) {
  const result = HEX_REG.exec(hex.toLowerCase());
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

export const COLOR_TYPES = {
  HEX: 'Hex',
  RGB: 'RGB',
  SASS: 'Sass',
};

export class Palette extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      copyTitle: COPY_TITLE,
      showSnackbar: false,
    };
  }

  static defaultProps = {
    to: COLOR_TYPES.HEX,
  };

  getCode() {
    const {
      color,
      to,
      name,
      code,
    } = this.props;

    switch (to) {
      case COLOR_TYPES.RGB: {
        const r = hexToRgb(color);
        return `rgb(${r.r}, ${r.g}, ${r.b})`;
      }
      case COLOR_TYPES.SASS: {
        return `$${code || name}`;
      }
      case COLOR_TYPES.HEX:
      default: {
        return color.toLowerCase();
      }
    }
  }

  onCopy = (code) => {
    const input = document.createElement('input');
    input.className = 'palette__input';
    input.value = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    this.setState({
      copyTitle: COPIED_TITLE,
      showSnackbar: true,
    });

    setTimeout(() => this.setState({
      copyTitle: COPY_TITLE,
      showSnackbar: false,
    }), 2000);
  };

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    const {
      name,
      color,
    } = this.props;

    const {
      showSnackbar,
    } = this.state;

    const code = this.getCode();
    return (
      <Fragment>
        <Card
          title={name}
          subtitle={code}
        >
          <div className="palette" style={{ backgroundColor: color }}/>
          <CardHoveredContent>
            <ButtonCircle onClick={() => this.onCopy(code)} inverse={true} iconName="Copy"/>
          </CardHoveredContent>
        </Card>
        <Snackbar
          open={showSnackbar}
          onClose={this.handleCloseSnackbar}
          message={COPIED_TITLE}
          action={[
            <span key="close" onClick={this.handleCloseSnackbar} className="palette__snackbar-action">CLOSE</span>
          ]}
        />
      </Fragment>
    )
  }
}

Palette.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  to: PropTypes.oneOf(Object.values(COLOR_TYPES)),
};
