import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './pagePartialHeader/style.scss';

export class PagePartialHeader extends Component {
  render() {
    const {
      title,
      navigation,
    } = this.props;

    return (
      <div className="page__header row no-gutters">
        <h2 className="col-12">{title}</h2>
        {navigation && navigation.length &&
        <ul className="page-nav col-12">
          {navigation.map((item, index) => {
            return (
              <li key={index} className="page-nav__item">
                <a className="page-nav__link" href={item.link}>{item.title}</a>
              </li>
            )
          })}
        </ul>
        }
      </div>
    );
  }
}

PagePartialHeader.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.arrayOf(PropTypes.object),
};
