import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { ProgressCircle, SuccessCircle, ErrorCircle } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class ProgressCirclesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  pageNavigation = [
    { title: 'ProgressCircle', link: '#progressCircle' },
    { title: 'SuccessCircle', link: '#successCircle' },
    { title: 'ErrorCircle', link: '#errorCircle' },
    { title: 'React usage', link: '#react' },
  ];

  render() {
    return (
      <PagePartial
        title="Progress, Success & Error Circles"
        navigation={this.pageNavigation}
      >
        <h5 id="progressCircle">ProgressCircle</h5>
        <div className="row example">
          <div className="col-4">
            <h4 className="title-card mb-2">default</h4>
            <ProgressCircle />
          </div>
          <div className="col-8">
            <h4 className="title-card mb-4">size: 20, borderWidth: 2</h4>
            <ProgressCircle size={20} borderWidth={2} />
          </div>
        </div>
        <h5 id="successCircle">SuccessCircle</h5>
        <div className="row example">
          <div className="col-4">
            <h4 className="title-card mb-2">default</h4>
            <SuccessCircle />
          </div>
          <div className="col-8">
            <h4 className="title-card mb-4">size: 20, borderWidth: 2, lineWidth: 2</h4>
            <SuccessCircle size={20} borderWidth={2} lineWidth={1} />
          </div>
        </div>
        <h5 id="errorCircle">ErrorCircle</h5>
        <div className="row example">
          <div className="col-4">
            <h4 className="title-card mb-2">default</h4>
            <ErrorCircle />
          </div>
          <div className="col-8">
            <h4 className="title-card mb-4">size: 20, borderWidth: 2, lineWidth: 2</h4>
            <ErrorCircle size={20} borderWidth={2} lineWidth={1} />
          </div>
        </div>

        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React from 'react';
                import { ProgressCircle, SuccessCircle, ErrorCircle } from 'factor';

                class YourPage extends React.Component {

                  render() {
                    return (<>
                      <ProgressCircle size={20} borderWidth={2} />
                      <SuccessCircle size={20} borderWidth={2} lineWidth={1} />
                      <ErrorCircle size={20} borderWidth={2} lineWidth={1} />
                    </>);
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Progress, Success & Error Circles Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>custom css class</td>
              </tr>
              <tr>
                <td>size</td>
                <td>number</td>
                <td>64px (4rem)</td>
                <td>circle diameter</td>
              </tr>
              <tr>
                <td>borderWidth</td>
                <td>number</td>
                <td>4px (0.25rem)</td>
                <td>circle border width</td>
              </tr>
              <tr>
                <td>lineWidth</td>
                <td>number</td>
                <td>2px (0.125rem)</td>
                <td>circle inner figure line width</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
