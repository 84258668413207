import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import {
  Icon,
  commonIcons,
  creativesIcons,
  audienceIcons,
  eventsIcons,
  statusIcons,
  osIcons,
  filterIcons,
  campaignsIcons,
  applicationsIcons,

  creativeIcons,
  deviceIcons,
} from 'factor';
import './iconsPage/style.scss';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';

export class IconsPage extends Component {
  pageNavigation = [
    { title: 'React Usage', link: '#react' },
    { title: 'Common Icons', link: '#common-icons' },
    { title: 'Creatives Icons', link: '#creatives-icons' },
    { title: 'Audience Icons', link: '#audience-icons' },
    { title: 'Events Icons', link: '#events-icons' },
    { title: 'Status Icons', link: '#status-icons' },
    { title: 'OS Icons', link: '#os-icons' },
    { title: 'Filter Icons', link: '#filter-icons' },
    { title: 'Campaigns Icons', link: '#campaigns-icons' },
    { title: 'Applications Icons', link: '#applications-icons' },
    { title: 'Old Creative Icons', link: '#old-creative-icons' },
    { title: 'Old Device Icons', link: '#old-device-icons' },
  ];

  commonIcons = Object.keys(commonIcons);
  creativesIcons = Object.keys(creativesIcons);
  audienceIcons = Object.keys(audienceIcons);
  eventsIcons = Object.keys(eventsIcons);
  statusIcons = Object.keys(statusIcons);
  osIcons = Object.keys(osIcons);
  filterIcons = Object.keys(filterIcons);
  campaignsIcons = Object.keys(campaignsIcons);
  applicationsIcons = Object.keys(applicationsIcons);

  creativeIcons = Object.keys(creativeIcons);
  deviceIcons = Object.keys(deviceIcons);

  render() {
    return (
      <PagePartial
        className="page-icons"
        navigation={this.pageNavigation}
        title="Icons"
      >
        <h5 id="common-icons">Common Icons</h5>
        <div className="row example">
          {this.commonIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">{name}</p>
              </div>
            )
          })}
        </div>

        <h5 id="creatives-icons">Creatives Icons</h5>
        <div className="row example">
          {this.creativesIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="audience-icons">Audience Icons</h5>
        <div className="row example">
          {this.audienceIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="events-icons">Events Icons</h5>
        <div className="row example">
          {this.eventsIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="status-icons">Status Icons</h5>
        <div className="row example">
          {this.statusIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="os-icons">OS Icons</h5>
        <div className="row example">
          {this.osIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="filter-icons">Filter Icons</h5>
        <div className="row example">
          {this.filterIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  <small>
                    {name}
                  </small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="campaigns-icons">Campaigns Icons</h5>
        <div className="row example">
          {this.campaignsIcons.map(name => {
            return (
              <div className="col-3 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">
                  {name}
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="applications-icons">Applications Icons</h5>
        <div className="row example">
          {this.applicationsIcons.map(name => {
            return (
              <div className="col-3 d-flex justify-content-center flex-column align-items-center" key={name}>
                <i style={{ width: '2.5rem' }}>
                  <Icon name={name} className="_default" />
                </i>
                <p>
                  <small>{name}</small>
                </p>
              </div>
            )
          })}
        </div>

        <h5 id="old-creative-icons">Old Creative Icons</h5>
        <div className="row example">
          {this.creativeIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">{name}</p>
              </div>
            )
          })}
        </div>

        <h5 id="old-device-icons">Old Device Icons</h5>
        <div className="row example">
          {this.deviceIcons.map(name => {
            return (
              <div className="col-2 d-flex justify-content-center flex-column align-items-center" key={name}>
                <Icon name={name} />
                <p className="paragraph">{name}</p>
              </div>
            )
          })}
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Icons with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Icon } from 'factor';
                // For using default styles import style
                import 'factor/src/scss/_icon.scss';

                class YourPage extends React.Component {
                  render() {
                    return (
                      <Icon name="XLS"/>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="required">name</td>
                <td>string (icon name above)</td>
                <td>none</td>
                <td>Icon name</td>
              </tr>
              <tr>
                <td>fill</td>
                <td>string</td>
                <td>none</td>
                <td>Svg color</td>
              </tr>
              <tr>
                <td>height</td>
                <td>string</td>
                <td>none</td>
                <td>Icon height</td>
              </tr>
              <tr>
                <td>width</td>
                <td>string</td>
                <td>none</td>
                <td>Icon width</td>
              </tr>
              <tr>
                <td>...rest</td>
                <td>object</td>
                <td>none</td>
                <td>Any svg attributes</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    )
  }
}
