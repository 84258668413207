import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Palette, COLOR_TYPES } from './colorPage/Palette';
import './colorPage/style.scss';

export class ColorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toColor: COLOR_TYPES.HEX,
    };
  }

  pageNavigation = [
    { title: 'Primary', link: '#primary'},
    { title: 'Secondary', link: '#secondary'},
    { title: 'Communication', link: '#communication'},
    { title: 'Chart', link: '#chart'},
  ];

  primary = [
    { name: 'primary-darkest', color: '#323C82' },
    { name: 'primary-dark', color: '#4D5ECC' },
    { name: 'primary', color: '#6075FF' },
    { name: 'primary-light', color: '#5F74FF' },
    { name: 'primary-lighter', color: 'rgba(96,117,255,.125)' },
    { name: 'primary-lightest', color: 'rgba(96,117,255,.08)' },
  ];

  secondary = [
    { name: 'secondary-darkest', color: '#5F6368' },
    { name: 'secondary-dark', color: '#A2A2A2' },
    { name: 'secondary', color: '#CBCBCB' },
    { name: 'secondary-light', color: '#F4F4F4' },
    { name: 'secondary-lighter', color: '#F8F8F8' },
  ];

  communication = [
    { name: 'good', color: '#3F9B34' },
    { name: 'include', color: 'rgba(63,155,52,.5)' },
    { name: 'warning', color: '#FFA300' },
    { name: 'bad', color: '#9B3434' },
    { name: 'exclude', color: 'rgba(155,52,52,.5)' },
    { name: 'danger', color: '#FF5859' },
  ];

  chart = [
    { name: 'charts-darkest', color: '#013627' },
    { name: 'charts-dark', color: '#008B69' },
    { name: 'charts', color: '#28BC97' },
    { name: 'charts-light', color: '#68EFC8' },
    { name: 'charts-lighter', color: '#C9FFEF' },
  ];

  renderListColors(colors) {
    const { toColor } = this.state;

    return colors.map((color, index) => {
      return (
        <div key={index} className="col-4 mt-4">
          <Palette to={toColor} {...color}/>
        </div>
      );
    });
  }

  render() {
    return (
      <PagePartial
        className="page-color"
        title="Color"
        navigation={this.pageNavigation}
      >
        <h5 id="primary">Primary</h5>
        <div className="row mt-4 mb-5">
          {this.renderListColors(this.primary)}
        </div>
        <h5 id="secondary">Secondary</h5>
        <div className="row mt-4 mb-5">
          {this.renderListColors(this.secondary)}
        </div>
        <br/>
        <h5 id="communication">Communication</h5>
        <div className="row mt-4 mb-5">
          {this.renderListColors(this.communication)}
        </div>
        <br/>
        <h5 id="chart">Chart</h5>
        <div className="row mt-4">
          {this.renderListColors(this.chart)}
        </div>
      </PagePartial>
    );
  }
}
