import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { ButtonCircle, Tooltip } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class TooltipPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  render() {
    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Tooltip"
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-4 d-flex justify-content-center align-items-center">
            <Tooltip label="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.">
              <div>Simple text</div>
            </Tooltip>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <Tooltip label="Notification" delayTime={0}>
              <ButtonCircle iconName="Notification" />
            </Tooltip>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <Tooltip label="Some action">
              <a href="/" className="link">Default Link</a>
            </Tooltip>
          </div>
        </div>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using Tooltip use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Tooltip } from 'factor';
                import 'factor/src/scss/_tooltip.scss';

                class YourPage extends React.Component {
                  render() {
                    return (
                      <Tooltip label="Some action">
                        <a href="/" className="link">Default Link</a>
                      </Tooltip>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="required">label</td>
              <td>string | element</td>
              <td>none</td>
              <td>Tooltip content</td>
            </tr>
            <tr>
              <td>delayTime</td>
              <td>number</td>
              <td>500</td>
              <td>Time before the tooltip will appear</td>
            </tr>
            <tr>
              <td>position</td>
              <td>string: top | right | bottom | left | bottom-left | bottom-right | top-left | top-right</td>
              <td>top</td>
              <td>Position for showing tooltip</td>
            </tr>
            <tr>
              <td>auto</td>
              <td>boolean</td>
              <td>true</td>
              <td>Trying auto detect position</td>
            </tr>
            <tr>
              <td>labelMaxWidth</td>
              <td>number</td>
              <td>500</td>
              <td>Limits tooltip width</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
