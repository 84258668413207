import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PagePartialHeader } from './PagePartialHeader';

export class PagePartial extends Component {
  render() {
    const {
      className = '',
      children,
      title,
      navigation,
    } = this.props;

    return (
      <article className={['page', className].join(' ').trim()}>
        <PagePartialHeader
          title={title}
          navigation={navigation}
        />
        <div className="page__content">
          {children}
        </div>
      </article>
    );
  }
}

PagePartial.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  navigation: PropTypes.arrayOf(PropTypes.object),
};
