import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Card, CardHoveredContent, ButtonCircle, CardHoveredSubtitle, CardSubtitle } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import './cardsPage/styles.scss';

export class CardsPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example'},
    { title: 'React usage', link: '#react'},
  ];

  render() {
    return (
      <PagePartial
        title="Cards"
        className="page-cards"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <Card
              title="Simple Card"
              subtitle="Subtitle"
            >
              <div className="row no-gutters">Some content</div>
              <div className="row no-gutters">Some content</div>
              <div className="row no-gutters">Some content</div>
            </Card>
          </div>
          <div className="col-6">
            <Card
              title={<span>Card with hover</span>}
              subtitle={({hovered}) => {
                return hovered ?
                  <CardHoveredSubtitle>I'm hovered</CardHoveredSubtitle> :
                  <CardSubtitle>Im not hovered</CardSubtitle>
              }}
            >
              <div className="row no-gutters">Some content</div>
              <div className="row no-gutters">Some content</div>
              <div className="row no-gutters">Some content</div>
              <CardHoveredContent>
                <div className="d-flex align-items-center card-icons">
                  <ButtonCircle iconName="XLS" inverse={true}/>
                  <ButtonCircle iconName="Copy" inverse={true}/>
                  <ButtonCircle iconName="Delete" className="btn-circle_remove" inverse={true}/>
                </div>
              </CardHoveredContent>
            </Card>
          </div>
          <div className="col-6 mt-4">
            <Card
              title="One more dynamic card"
            >
              {({hovered}) => {
                return hovered ? (
                  <div>
                    This is content if hovered
                  </div>
                ) : (
                  <div>
                    This is simple content
                  </div>
                );
              }}
            </Card>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Card, CardHoveredContent, CardHoveredSubtitle, CardSubtitle } from 'factor';
                import 'factor/src/scss/_card.scss';

                class YourPage extends React.Component {
                  render() {
                    return (
                        <div>
                            <Card
                              title="Simple Card"
                              subtitle="Subtitle"
                            >
                              <div className="row no-gutters">Some content</div>
                              <div className="row no-gutters">Some content</div>
                              <div className="row no-gutters">Some content</div>
                            </Card>
                            <Card
                              title={<span>Card with hover</span>}
                              subtitle={({hovered}) => {
                                return hovered ?
                                  <CardHoveredSubtitle>I'm hovered</CardHoveredSubtitle> :
                                  <CardSubtitle>Im not hovered</CardSubtitle>
                              }}
                            >
                              <div className="row no-gutters">Some content</div>
                              <div className="row no-gutters">Some content</div>
                              <div className="row no-gutters">Some content</div>
                              <CardHoveredContent>
                                <div>
                                  <Button className="btn-square _orange">Copy</Button>
                                </div>
                              </CardHoveredContent>
                            </Card>
                            <Card
                              title="One more dynamic card"
                            >
                              {({hovered}) => {
                                return hovered ? (
                                  <div>
                                    This is content if hovered
                                  </div>
                                ) : (
                                  <div>
                                    This is simple content
                                  </div>
                                );
                              }}
                            </Card>
                        </div>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>title</td>
              <td>string | element</td>
              <td>none</td>
              <td>Card title</td>
            </tr>
            <tr>
              <td>subtitle</td>
              <td>string | element | function(state): element <br/>
                for animation use CardHoveredSubtitle and CardSubtitle
              </td>
              <td>none</td>
              <td>Card subtitle</td>
            </tr>
            <tr>
              <td>children</td>
              <td>element | function(state): element <br/>
                for hover content use CardHoveredContent
              </td>
              <td>none</td>
              <td>Card content</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
