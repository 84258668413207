import React, { Component } from 'react';
import { Tumbler } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import './tumblerPage/tumblerPage.scss';
import { PagePartial } from '../demoComponents/PagePartial';

export class TumblerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleTumbler: false,
      onOffTumbler: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example', },
    { title: 'React usage', link: '#react', },
  ];

  render() {
    const {
      simpleTumbler,
      onOffTumbler,
    } = this.state;

    const tumbler1 = <Tumbler
      onOff={false}
      className=""
      on={simpleTumbler}
      onChange={(value) => this.setState({
        simpleTumbler: !simpleTumbler,
      })}
      title="Title"
    />;

    const tumbler2 = <Tumbler
      onOff={true}
      className=""
      on={onOffTumbler}
      onChange={(value) => this.setState({
        onOffTumbler: !onOffTumbler,
      })}
    />;

    return (
      <PagePartial
        title={'Tumbler'}
        className="tumbler-page"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-6">
            {tumbler1}
          </div>
          <div className="col-6">
            {tumbler2}
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this tumbler with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Tumbler } from 'factor';
                import 'factor/src/scss/_tumbler.scss';

                class YourPage extends React.Component {
                  constructor() {
                    super();
                    this.state = {
                      simpleTumbler: false,
                    };
                  }

                  render() {
                    const {
                      simpleTumbler,
                    } = this.state;

                    return (
                      <Tumbler
                        onOff={false}
                        className=""
                        on={simpleTumbler}
                        onChange={(value) => this.setState({
                          simpleTumbler: !simpleTumbler,
                        })}
                        title="Title"
                      />
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="required">onnOff</td>
                <td>boolean</td>
                <td>false</td>
                <td>If tumbler has on/off titles</td>
              </tr>
              <tr>
                <td className="required">onChange</td>
                <td>function</td>
                <td>none</td>
                <td>Callback when value changed</td>
              </tr>
              <tr>
                <td className="required">on</td>
                <td>boolean</td>
                <td>false</td>
                <td>If tumbler is active or not</td>
              </tr>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Tumbler wrapper class name</td>
              </tr>
              <tr>
                <td>title</td>
                <td>string or react element</td>
                <td>none</td>
                <td>Tumbler title at the right</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
