import React, { Component, Fragment } from 'react';
import { ButtonCircle, Table } from 'factor';
import { generateUsersTable } from './dataGenerator';

const SERVER_DATA_LENGTH = 125;
const data = generateUsersTable(SERVER_DATA_LENGTH);

export class ServerTableEmulation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paging: {
        activePage: 1,
        itemsCountPerPage: 10,
      },
      data: data.slice(),
      serverData: [],
      loading: true,
    };
  }

  table = {
    header: [
      { label: 'Name', sortingKey: 'name' },
      { label: 'Role', className: '_role_header' },
      { label: 'Member Since', className: '_col-120' },
      { label: 'Last Active' },
      { className: '' },
    ],
    body: [
      { key: 'name' },
      {
        key: (d) => {
          return d.role.label;
        },
        className: '_role',
      },
      { key: 'date', className: '_col-120' },
      { key: 'lastActive' },
      {
        className: '_actions',
        hover: (item, field) => {
          return <Fragment>
            <ButtonCircle className="_size-19" outline={true} iconName="Edit" onClick={() => console.log(item, field)} />
            <ButtonCircle className="_size-19" outline={true} iconName="Delete" onClick={() => console.log(item, field)} />
          </Fragment>
        },
      },
    ],
    sorting: {
      direction: 'desc',
      field: 'name',
    },
  };

  handleTableChange = ({ paging, sorting }) => {
    this.setState({
      loading: true,
    });

    const {
      data
    } = this.state;

    const {
      activePage,
      itemsCountPerPage,
    } = paging;

    if (sorting) {
      if (sorting.direction === 'asc') {
        data.sort((a, b) => {
          return a[sorting.field] > b[sorting.field] ? 1 : -1;
        });
      } else {
        data.sort((a, b) => {
          return a[sorting.field] < b[sorting.field] ? 1 : -1;
        });
      }
    }

    const serverData = data.slice((activePage - 1) * itemsCountPerPage, itemsCountPerPage * activePage);

    this.setState({
      paging,
    });

    setTimeout(() => {
      this.setState({
        serverData,
        loading: false,
      });
    }, 1500);
  };

  render() {
    return (
      <Table
        header={this.table.header}
        title={`Users (${SERVER_DATA_LENGTH})`}
        body={this.table.body}
        data={this.state.serverData}
        paging={{
          ...this.state.paging,
          totalItemsCount: SERVER_DATA_LENGTH,
        }}
        server={true}
        loading={this.state.loading}
        onChange={this.handleTableChange}
        sorting={this.table.sorting}
        className="table__users"
        offsetTop={64}
        rowKeyExtractor={(data) => data.id}
        fixedHeader={false}
      />
    );
  }
}
