import React from 'react';
import { Checkbox } from 'factor';
import { ReactComponent as CbChecked } from '../svg/check-box-24px.svg';
import { ReactComponent as CbBlank } from '../svg/check-box-blank-24px.svg';

export class CheckboxFolHtml extends Checkbox {
  render() {
    const {
      name,
      label,
      checked,
      disabled,
      onChange,
    } = this.props;

    return (
      <div className={`checkbox ${disabled ? 'checkbox_disabled' : ''}`}>

        <label className="checkbox__label" htmlFor={name}>
          <input
            type="checkbox"
            id={name}
            name={name}
            disabled={disabled}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            className="checkbox__input"
          />
          <div className="checkbox-svg-checked">
            <CbChecked />
          </div>
          <div className="checkbox-svg-blank">
            <CbBlank />
          </div>
          {label}
        </label>
      </div>
    );
  }
}
