import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Pagination } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class PaginationPage extends Component {
  navigationPage = [
    { title: 'Example', link: '#example' }
  ];

  constructor(props) {
    super(props);

    this.state = {
      activePage: 5,
      totalItems: 150,
      perPage: 10,
    }
  }

  onNavigationChanged = (page) => {
    this.setState({
      activePage: page,
    });
  };

  render() {
    const {
      activePage,
      totalItems,
      perPage,
    } = this.state;

    return (
      <PagePartial
        navigation={this.navigationPage}
        title="Pagination"
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-12">
            <Pagination
              activePage={activePage}
              totalItemsCount={totalItems}
              itemsCountPerPage={perPage}
              onChange={this.onNavigationChanged}
            />
          </div>
        </div>
        <br/>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Pagination with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Pagination } from 'factor';
                import 'factor/src/scss/_pagination.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      activePage: 5,
                      totalItems: 150,
                      perPage: 10,
                    }
                  }

                  onNavigationChanged = (page) => {
                    this.setState({
                      activePage: page,
                    });
                  };

                  render() {
                    const {
                      activePage,
                      totalItems,
                      perPage,
                    } = this.state;

                    return (
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={totalItems}
                        itemsCountPerPage={perPage}
                        onChange={this.onNavigationChanged}
                      />
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            <tr>
              <td className="required">activePage</td>
              <td>number</td>
              <td>1</td>
              <td>Active page number</td>
            </tr>
            <tr>
              <td className="required">totalItemsCount</td>
              <td>number</td>
              <td>none</td>
              <td>Total items</td>
            </tr>
            <tr>
              <td className="required">itemsCountPerPage</td>
              <td>number</td>
              <td>10</td>
              <td>Items per page</td>
            </tr>
            <tr>
              <td className="required">onChange</td>
              <td>function(page)</td>
              <td>none</td>
              <td>Pagination change callback</td>
            </tr>
            <tr>
              <td>pageRangeDisplayed</td>
              <td>number</td>
              <td>5</td>
              <td>Number of displayed pages</td>
            </tr>
            </thead>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
