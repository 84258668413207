import 'factor/dist/fonts.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header } from './demoComponents/Header';
import { Drawer } from './demoComponents/Drawer';
import ScrollToTop from './demoComponents/ScrollToTopRouter';

import routes from './routes/index';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: true,
    };
  }

  handleToggleMenu = () => {
    const {
      menuOpen,
    } = this.state;

    this.setState({
      menuOpen: !menuOpen,
    });
  };

  appContent = (props) => (
    props.on ? (
      <div className="app-content">
        <Drawer />
        <section className="app-content__main">
          {props.children}
        </section>
      </div>
    ) : props.children
  )

  render() {
    const {
      isComponentsLayout,
    } = this.props;

    const {
      menuOpen,
    } = this.state;

    const AppContent = this.appContent;

    return (
      <Router>
        <ScrollToTop>
          <div className={`app ${menuOpen ? 'app__menu-open' : ''}`}>
            <Header onMenuToggle={this.handleToggleMenu} />
            <AppContent on={isComponentsLayout}>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact={route.exact}
                  component={route.component}
                  path={route.path}
                />
              ))}
            </AppContent>
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default connect(
  state => ({
    isComponentsLayout: state.isComponentsLayout,
  }),
)(App);

App.propTypes = {
  isComponentsLayout: PropTypes.bool,
};
