import React, { PureComponent } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import './contextMenuPage/styles.scss';

import { Example1, Example2 } from './contextMenuPage/ExampleComponents';

const contextComponents = {
  menu1: Example1,
  menu2: Example2,
};

export class ContextMenuPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentMenu: null,
      clientX: 0,
      clientY: 0,
    };

    this.handleOpenMenu1 = this.handleContextMenu.bind(this, 'menu1');
    this.handleOpenMenu2 = this.handleContextMenu.bind(this, 'menu2');
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  handleContextMenu = (name, event) => {
    event.preventDefault();

    this.setState({
      currentMenu: name,
      clientX: event.clientX,
      clientY: event.clientY,
    });
  };

  handleClose = () => {
    this.setState({
      currentMenu: null,
      clientX: 0,
      clientY: 0,
    })
  };

  render() {
    let contextComponent = null;
    const {
      currentMenu,
      clientX,
      clientY,
    } = this.state;

    if (currentMenu) {
      const Component = contextComponents[currentMenu];
      contextComponent = <Component
        onClose={this.handleClose}
        clientX={clientX}
        clientY={clientY}
      />
    }
    return (
      <PagePartial
        title="Context Menu"
        navigation={this.pageNavigation}
        className="page-context-menu"
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-6">
            <div className="context-zone" onContextMenu={this.handleOpenMenu1}>
              <div className="context-zone__title">
                Click right mouse button here
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="context-zone" onContextMenu={this.handleOpenMenu2}>
              <div className="context-zone__title">
                Click right mouse button here
              </div>
            </div>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Context menu with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { ContextMenu, ContextMenuList, ContextMenuListDivider, ContextMenuListItem } from 'factor';
                import 'factor/src/scss/_context-menu.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      open: false,
                      clientX: 0,
                      clientY: 0,
                    };
                  }

                  onClose = () => {
                    this.setState({
                        open: false,
                    });
                  };

                  render() {
                    const {
                      open,
                      clientX,
                      clientY,
                    } = this.state;

                    return (
                        {open && <ContextMenu onClose={this.onClose} clientX={clientX} clientY={clientY}>
                          <ContextMenuList>
                            <ContextMenuListItem title={true}>
                              Title
                            </ContextMenuListItem>
                            <ContextMenuListItem>
                              Item 1
                            </ContextMenuListItem>
                            <ContextMenuListItem>
                              Item 2
                            </ContextMenuListItem>
                            <ContextMenuListDivider/>
                            <ContextMenuListItem>
                              Item 3
                            </ContextMenuListItem>
                            <ContextMenuListItem>
                              Item 4
                            </ContextMenuListItem>
                          </ContextMenuList>
                        </ContextMenu>
                        }
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr className="required">
              <td>clientX</td>
              <td>number</td>
              <td>none</td>
              <td>Client X position</td>
            </tr>
            <tr className="required">
              <td>clientY</td>
              <td>number</td>
              <td>none</td>
              <td>Client Y prosition</td>
            </tr>
            <tr className="required">
              <td>onClose</td>
              <td>function</td>
              <td>none</td>
              <td>Close handler</td>
            </tr>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>none</td>
              <td>Additional classes</td>
            </tr>
            <tr>
              <td>rest</td>
              <td>object</td>
              <td>none</td>
              <td>Any div properties</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Handlers</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Params</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>ContextMenuList</td>
              <td>...rest | any ul properties</td>
              <td>List wrapper with 'ul' tag</td>
            </tr>
            <tr>
              <td>ContextMenuListItem</td>
              <td>...rest | any li properties</td>
              <td>Item wrapper with 'li' tag</td>
            </tr>
            <tr>
              <td>ContextMenuListDivider</td>
              <td>...rest | any li properties</td>
              <td>Styled divider wrapper with 'li' tag</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        {contextComponent}
      </PagePartial>
    );
  }
}
