import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';

export class TypographyPage extends Component {
  pageNavigation = [
    { title: 'Headings', link: '#headings' },
    { title: 'Paragraphs', link: '#paragraphs' },
  ];

  render() {
    const head1 = <h1 className="title-main">Main title</h1>;
    const head2 = <h2 className="title-main-subtitle">Main subtitle</h2>;
    const head3 = <h3 className="title-form">Form title</h3>;
    const head4 = <h4 className="title-form-subtitle">Form subtitle</h4>;
    const head5 = <h5 className="title-card">Card title</h5>;
    const head6 = <h6 className="title-card-subtitle">Card subtitle</h6>;
    const textLink = <a href="https://iqm.com/" target="_blank" rel="noopener noreferrer" className="text-link">Link</a>;

    const p = <p className="paragraph">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda corporis cum debitis dicta ea explicabo id itaque laboriosam modi, nam nihil odio officiis optio pariatur quaerat quas qui unde velit. Architecto aspernatur consequuntur iste laboriosam nobis odit optio porro, repellendus? Architecto dolores expedita laudantium libero natus nobis numquam soluta tempore tenetur ut. Asperiores blanditiis dignissimos exercitationem quas, quos recusandae tempora! Cupiditate necessitatibus praesentium sapiente. Animi at deleniti, dolore earum inventore iusto non perspiciatis quae totam vel. A dolor, dolorem ipsa iste magnam molestiae necessitatibus officiis perspiciatis quod reiciendis veniam voluptate?
    </p>;
    const pBig = <p className="paragraph paragraph_large">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda corporis cum debitis dicta ea explicabo id itaque laboriosam modi, nam nihil odio officiis optio pariatur quaerat quas qui unde velit. Architecto aspernatur consequuntur iste laboriosam nobis odit optio porro, repellendus? Architecto dolores expedita laudantium libero natus nobis numquam soluta tempore tenetur ut. Asperiores blanditiis dignissimos exercitationem quas, quos recusandae tempora! Cupiditate necessitatibus praesentium sapiente. Animi at deleniti, dolore earum inventore iusto non perspiciatis quae totam vel. A dolor, dolorem ipsa iste magnam molestiae necessitatibus officiis perspiciatis quod reiciendis veniam voluptate?
    </p>;

    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Typography"
      >
        <h5 id="headings">Headings</h5>
        <div className="example">
          <div className="row">
            <div className="offset-6" />
            <p className="col-3 align-right mb-1">Rem</p>
            <p className="col-3 mb-1">Lato</p>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head1}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">3</div>
            <div className="col-3 title-form-subtitle opacity-50">Black</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head2}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">2.25</div>
            <div className="col-3 title-form-subtitle opacity-50">Black</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head3}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">1.25</div>
            <div className="col-3 title-form-subtitle opacity-50">Black</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head4}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">2</div>
            <div className="col-3 title-form-subtitle opacity-50">Light</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head5}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">1.5</div>
            <div className="col-3 title-form-subtitle opacity-50">Medium</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{head6}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">1</div>
            <div className="col-3 title-form-subtitle opacity-50">Regular</div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">{textLink}</div>
            <div className="col-3 title-form-subtitle opacity-50 align-right">0.875</div>
            <div className="col-3 title-form-subtitle opacity-50">Regular</div>
          </div>
        </div>
        <PagePartialUsageBlock>
          <Highlight>
            {head1}
            {head2}
            {head3}
            {head4}
            {head5}
            {head6}
            {textLink}
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <h5 id="paragraphs">Paragraphs</h5>
        <h5 className="title-card mt-3">Paragraph</h5>
        <div className="row">
          <div className="col-6">{p}</div>
          <div className="col-6">
            <div className="row">
              <div className="col-6 align-right">Rem</div>
              <div className="col-6">Lato</div>
              <div className="col-6 align-right title-form-subtitle opacity-50">1</div>
              <div className="col-6 title-form-subtitle opacity-50">Regular</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Highlight>{p}</Highlight>
          </div>
        </div>
        <h5 className="title-card mt-3">Paragraph large</h5>
        <div className="row">
          <div className="col-6">{pBig}</div>
          <div className="col-6">
            <div className="row">
              <div className="col-6 align-right">Rem</div>
              <div className="col-6">Lato</div>
              <div className="col-6 align-right title-form-subtitle opacity-50">1.5</div>
              <div className="col-6 title-form-subtitle opacity-50">Regular</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Highlight>{pBig}</Highlight>
          </div>
        </div>
      </PagePartial>
    );
  }
}
