import React, { PureComponent } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Select, Checkbox, Button, Icon, Dialog, DialogContent, DialogButtons } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import testLabelImage from './selectPage/test-image.png';
import './selectPage/styles.scss';

const tableData = [
  {
    name: 'label',
    type: 'string',
    defaultValue: 'none',
    description: 'Label for control',
  },
  {
    name: 'placeholder',
    type: 'string',
    defaultValue: 'none',
    description: 'Placeholder for control',
  },
  {
    name: 'tumblerLabel',
    type: 'string',
    defaultValue: 'Show Groups',
    description: 'Label for tumbler',
  },
  {
    name: 'selectAllLabel',
    type: 'string',
    defaultValue: 'Select All',
    description: "Label for 'Select all' button",
  },
  {
    name: 'showControlLabel',
    type: 'boolean',
    defaultValue: 'false',
    description: 'If true, shows control label',
  },
  {
    name: 'isClearable',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Is the select value clearable',
  },
  {
    name: 'hideCrossIfClosed',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Show/hide clearing cross when drop-down is closed',
  },
  {
    name: 'hideValueOnFocus',
    type: 'boolean',
    defaultValue: 'false',
    description: 'If true, shows label in control instead selected value, when menu is open',
  },
  {
    name: 'tumbler',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Show/hide tumbler control',
  },
  {
    name: 'isMulti',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Single/Multi control',
  },
  {
    name: 'allSelectable',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Show/hide control for select all options',
  },
  {
    name: 'dnd',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Enable/disable drag and drop. To set fixed options you need to pass alwaysEnabled prop in required option',
  },
  {
    name: 'options',
    type: 'Array',
    defaultValue: '[]',
    description: 'Select options array',
  },
  {
    name: 'onChange',
    type: 'function',
    defaultValue: 'none',
    description: 'Callback after value selected',
  },
  {
    name: 'value',
    type: 'any',
    defaultValue: 'null',
    description: 'Current control value',
  },
  {
    name: 'levelPadding',
    type: 'number',
    defaultValue: '16',
    description: 'Padding for next options level',
  },
  {
    name: 'isSearchable',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Show/hide search input for filter options',
  },
  {
    name: 'searchByValue',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Add search by value field in options',
  },
  {
    name: 'wildcardSearch',
    type: 'boolean',
    defaultValue: 'false',
    description: 'Allows to search using wildcard characters % or *',
  },
  {
    name: 'searchString',
    type: 'string',
    defaultValue: null,
    description: 'External value for search-input',
  },
  {
    name: 'changeSearchHandler',
    type: 'function',
    defaultValue: null,
    description: 'External callback after changing the search-input value(value is the argument)',
  },
  {
    name: 'scrollableClassName',
    type: 'string',
    defaultValue: '_scrollable',
    description: 'Add class to select__list component when it is scrollable',
  },
  {
    name: 'tooltip',
    type: 'object',
    defaultValue: 'undefined',
    description: 'Adds tooltip to select button',
  },
  {
    name: 'isOpen',
    type: 'boolean',
    defaultValue: 'undefined',
    description: 'Sets isOpen state through prop',
  },
  {
    name: 'toggleOpenProp',
    type: 'function',
    defaultValue: 'undefined',
    description: 'Used for changing isOpen prop inside select component',
  },
  {
    name: 'ignoredCloseClickElement',
    type: 'HTMLElement',
    defaultValue: 'undefined',
    description: 'Used when isOpen prop is setting by clicking somewhere outside select (some button) to prevent firing select closing event on it',
  },
  {
    name: 'buttonProps',
    type: 'Object: { label: string, onClick: function, className: string, symbolBeforeLabel: string }',
    defaultValue: 'undefined',
    description: 'Button parameters inside select dropdown',
  },
  {
    name: 'onSelectAll',
    type: 'function',
    defaultValue: 'undefined',
    description: 'Callback for selectAll click',
  },
  {
    name: 'withTabs',
    type: 'boolean',
    defaultValue: 'undefined',
    description: 'Add include/exclude tabs inside select',
  },
  {
    name: 'maxSelectedLimit',
    type: 'number',
    defaultValue: 'undefined',
    description: 'Max number of selected items for multi-select',
  },
  {
    name: 'onExceededMaxLimit',
    type: 'function',
    defaultValue: 'undefined',
    description: 'Cb after exceeding of max limit value',
  },
  {
    name: 'withConfirmation',
    type: 'boolean',
    defaultValue: 'undefined',
    description: 'Show confirmation dialog after changing value',
  },
]

const bigArray = [];
for (let i = 0; i < 2000; i++) {
  bigArray.push({
    value: i,
    label: `Option ${i}`
  })
}

const ReactComponentOption = () => (
  <div className="d-flex align-items-center">
    test react
    <b className="ml-1 mr-3">
      component
    </b>
    <i className="mt-1">
      <Icon name="VoiceXS" />
    </i>
  </div>
);

export class SelectPage extends PureComponent {

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  options = [
    {
      label: 'fruits', isGroupHeader: true, options: [
        { value: 'apple', label: 'Apple' },
        { value: 'pear', label: 'Pear' },
        { value: 'banana', label: 'Banana' },
        { value: 'apricot', label: 'Apricot' },
        { value: 'clementine', label: 'Clementine' },
        { value: 'grapefruit', label: 'Grapefruit' },
        { value: 'grape', label: 'Grape' },
      ],
    },
    {
      label: 'berries', isGroupHeader: true, options: [
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'blueberry', label: 'Blueberry' },
        { value: 'raspberry', label: 'Raspberry' },
      ],
    }
  ];

  optionsWithChildren = [
    {
      value: 'number 1',
      label: 'Number 1',
      labelImageSrc: testLabelImage,
      options: [
        { value: 'number 1.1', label: 'Number 1.1' },
        { value: 'number 1.2', label: 'Number 1.2' },
        {
          value: 'number 1.3',
          label: 'Number 1.3',
          options: [
            { value: 'number 1.3.1', label: 'Number 1.3.1' },
            { value: 'number 1.3.2', label: 'Number 1.3.2' },
          ],
        },
      ],
    },
    {
      value: 'number 2',
      label: 'Number 2',
      labelImageSrc: 'wrong-image-src',
    },
    {
      value: 'number 3',
      label: 'Number 3',
      reactLabel: <ReactComponentOption />,
      uncountable: true,
      options: [
        { value: 'number 3.1', label: 'Number 3.1' },
      ],
    },
  ];

  columnsOptions = [
    { label: 'Campaign Name', value: 1, alwaysEnabled: true, draggableGroup: 1 },
    { label: 'Max Bid', value: 2, alwaysEnabled: true, draggableGroup: 1 },
    { label: 'Daily Budget', value: 3, draggableGroup: 1 },
    { label: 'Start Date', value: 4 },
    { label: 'Status', value: 5 },
    { label: 'Action', value: 6 },
    { label: 'Data Cost', value: 7 },
    { label: 'Type', value: 8 },
    { label: 'CTR', value: 9 },
    { label: 'Exchange', value: 11 },
    { label: 'OS', value: 12 },
    { label: 'ID', value: 13 },
  ]

  constructor(props) {
    super(props);

    this.state = {
      isSearchable: false,
      isClearable: false,
      hideCrossIfClosed: false,
      multi: false,
      selectAll: false,
      showControlLabel: false,
      tumbler: false,
      dnd: false,
      tumblerIsActive: false,
      searchByValue: false,
      selectedValue: null,
      button: false,
      withTabs: false,
      isDialogOpen: false,
      withConfirmation: false,
      tempValues: [],
    };
  }

  handleChangeChecked = (name) => (checked) => {
    let depends = {};
    if (name === 'dnd' || name === 'withTabs') {
      depends.multi = true
    }
    this.setState({
      [name]: checked,
      ...depends,
      selectedValue: null
    })
  }

  handleSelectedValue = (value) => {
    const { withConfirmation } = this.state;
    if (withConfirmation) {
      this.setState({ tempValues: value, isDialogOpen: true });
    } else {
      this.setState({ selectedValue: value });
    }
  }

  renderDialog = () => {
    const { isDialogOpen } = this.state;
    return (
      <Dialog open={isDialogOpen}>
        <DialogContent>
          <h5 className="title-card">Change Values</h5>
          <p className="mt-4 mb-4">Are you sure?</p>
          <div className="d-flex justify-content-between">
            <DialogButtons
              buttons={[
                {
                  title: 'Cancel',
                  handler: () => {
                    this.setState({
                      isDialogOpen: false,
                    })
                  },
                },
                {
                  title: 'Change',
                  handler: () => {
                    const { tempValues } = this.state;
                    this.setState({
                      selectedValue: tempValues,
                      tempValues: [],
                      isDialogOpen: false,
                    })
                  },
                  className: 'btn-square _conflower-blue _filled',
                },
              ]}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const {
      isSearchable,
      isClearable,
      hideCrossIfClosed,
      multi,
      selectAll,
      selectedValue,
      hideValueOnFocus,
      showControlLabel,
      tumbler,
      tumblerIsActive,
      dnd,
      searchByValue,
      button,
      withTabs,
      isDialogOpen,
      withConfirmation,
    } = this.state;

    return (
      <PagePartial
        title="Select"
        navigation={this.pageNavigation}
        className="page-select"
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Searchable'
              name='isSearchable'
              checked={isSearchable}
              onChange={this.handleChangeChecked('isSearchable')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Search by value'
              name='searchByValue'
              checked={searchByValue}
              onChange={this.handleChangeChecked('searchByValue')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Clearable'
              name='isClearable'
              checked={isClearable}
              onChange={this.handleChangeChecked('isClearable')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Hide clear cross if closed'
              name='hideCrossIfClosed'
              checked={hideCrossIfClosed}
              onChange={this.handleChangeChecked('hideCrossIfClosed')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Multi'
              name='multi'
              checked={multi}
              onChange={this.handleChangeChecked('multi')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Select all'
              name='selectAll'
              checked={selectAll}
              onChange={this.handleChangeChecked('selectAll')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Hide value on focus'
              name='hideValueOnFocus'
              checked={hideValueOnFocus}
              onChange={this.handleChangeChecked('hideValueOnFocus')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Show control label'
              name='showControlLabel'
              checked={showControlLabel}
              onChange={this.handleChangeChecked('showControlLabel')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Enable tumbler'
              name='tumbler'
              checked={tumbler}
              onChange={this.handleChangeChecked('tumbler')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Enable Drag and Drop'
              name='dnd'
              checked={dnd}
              onChange={this.handleChangeChecked('dnd')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Show button inside'
              name='button'
              checked={button}
              onChange={this.handleChangeChecked('button')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='Show tabs'
              name='withTabs'
              checked={withTabs}
              onChange={this.handleChangeChecked('withTabs')}
            />
          </div>
          <div className="col-4 mb-2">
            <Checkbox
              size='sm'
              label='With Confirmation'
              name='confirmation'
              checked={withConfirmation}
              onChange={this.handleChangeChecked('withConfirmation')}
            />
          </div>
          <div className="col-12 d-flex align-items-center">
            <Select
              placeholder='Select option...'
              label='Option'
              options={dnd ? this.columnsOptions : this.optionsWithChildren}
              value={selectedValue}
              isMulti={multi}
              allSelectable={selectAll}
              isSearchable={isSearchable}
              searchByValue={searchByValue}
              isClearable={isClearable}
              hideCrossIfClosed={hideCrossIfClosed}
              hideValueOnFocus={hideValueOnFocus}
              onChange={this.handleSelectedValue}
              showControlLabel={showControlLabel}
              tumbler={tumbler}
              dnd={dnd}
              tumblerIsActive={tumblerIsActive}
              onTumblerChange={() => this.setState({
                tumblerIsActive: !tumblerIsActive,
              })}
              tooltipParams={{
                label: 'Select tooltip',
              }}
              isOpen={this.state.isOpen}
              ignoredCloseClickElement={this.state.ignoredCloseClickElement}
              toggleOpenProp={(isOpen) => this.setState({ isOpen })}
              buttonProps={button ? {
                label: 'iqm.com',
                onClick: () => window.open('https://iqm.com'),
                className: '',
                symbolBeforeLabel: '+',
              } : null}
              withTabs={withTabs}
              withConfirmation={withConfirmation}
            />

            {isDialogOpen ? this.renderDialog() : null}

            <Button
              className="btn-square _filled _conflower-blue ml-4"
              onClick={(e) => {
                this.setState({
                  isOpen: !this.state.isOpen,
                  ignoredCloseClickElement: e.target,
                });
              }}
            >
              Select is {this.state.isOpen ? 'open' : 'closed'}
            </Button>
          </div>

        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Select with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Select } from 'factor';
                import 'factor/src/scss/_select.scss';

                const ReactComponentOption = () => (
                  <div className="d-flex align-items-center">
                    test react
                    <b className="ml-1 mr-3">
                      component
                    </b>
                    <i className="mt-1">
                      <Icon name="VoiceXS" />
                    </i>
                  </div>
                );

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      value: null,
                      value2: null,
                    };
                  }

                  options = [
                    {
                      label: 'fruits', isGroupHeader: true, options: [
                        { value: 'apple', label: 'Apple' },
                        { value: 'pear', label: 'Pear' },
                        { value: 'banana', label: 'Banana' },
                        { value: 'apricot', label: 'Apricot' },
                        { value: 'clementine', label: 'Clementine' },
                        { value: 'grapefruit', label: 'Grapefruit' },
                        { value: 'grape', label: 'Grape' },
                      ],
                    },
                    {
                      label: 'berries', isGroupHeader: true, options: [
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'blueberry', label: 'Blueberry' },
                        { value: 'raspberry', label: 'Raspberry' },
                      ],
                    }
                  ];

                  optionsWithChildren = [
                    {
                      value: 'number 1',
                      label: 'Number 1',
                      labelImageSrc: 'https://any-image-url.jpg',
                      options: [
                        { value: 'number 1.1', label: 'Number 1.1' },
                        { value: 'number 1.2', label: 'Number 1.2' },
                        {
                          value: 'number 1.3',
                          label: 'Number 1.3',
                          options: [
                            { value: 'number 1.3.1', label: 'Number 1.3.1' },
                            { value: 'number 1.3.2', label: 'Number 1.3.2' },
                          ],
                        },
                      ],
                    },
                    {
                      value: 'number 2',
                      label: 'Number 2',
                    },
                    {
                      value: 'number 3',
                      label: 'Number 3',
                      uncountable: true,
                      reactLabel: <ReactComponentOption />,
                      options: [
                        { value: 'number 3.1', label: 'Number 3.1' },
                      ],
                    },
                  ];

                  render() {
                    const {
                      value,
                      value2,
                    } = this.state;

                    return (
                      <div>
                          <Select
                            options={this.optionsWithChildren}
                            value={value}
                            withCheckbox={true}
                            isMulti={true}
                            allSelectable={true}
                            isSearchable={true}
                            onChange={(value) => this.setState({ value })}
                            placeholder='Select option...'
                            label='Option'
                            tooltipParams={{
                              label: 'Select tooltip'
                            }}
                          />
                          <Select
                            options={this.options}
                            value={value2}
                            allSelectable={true}
                            isSearchable={true}
                            onChange={(value) => this.setState({ value2: value })}
                            placeholder='Select option...'
                            label='Option'
                            isOpen={this.state.isOpen}
                            ignoredCloseClickElement={this.state.ignoredCloseClickElement}
                            toggleOpenProp={(isOpen) => this.setState({isOpen})}
                          />
                          <Button
                            className="btn-square _filled _conflower-blue ml-4"
                            onClick={(e) => {
                              this.setState({
                                isOpen: !this.state.isOpen,
                                ignoredCloseClickElement: e.target,
                              });
                            }}
                          >
                            Select is {this.state.isOpen ? 'open' : 'closed'}
                          </Button>
                      </div>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, i) => (
                <tr key={i}>
                  <td>{row.name}</td>
                  <td>{row.type}</td>
                  <td>{row.defaultValue}</td>
                  <td>{row.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  };
}
