import React from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Button, EditableText } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class EditableTextPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      value1: 'Hover me!',
      value2: 'One more value',
      value3: 42.05,
      value4: '21/09/2019'
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'Example with Amount', link: '#example-amount' },
    { title: 'Example with Date', link: '#example-date' },
    { title: 'React usage', link: '#react' },
  ];

  editableTextRef;

  render() {
    const {
      hovered,
      value1,
      value2,
      value3,
      value4
    } = this.state;

    return (
      <PagePartial
        title="Editable text"
        className="page-editabletext"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-3" onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}>
            <div style={{ display: hovered ? 'block' : 'none' }}>
              <EditableText
                value={value1}
                Popup={{
                  title: 'Edit text',
                  remember: true,
                  storageKey: 'editable_text',
                }}
                confirm={(value) => {
                  this.setState({
                    value1: value,
                  });
                }}
              >
                <div>Are you sure you want to change value?</div>
              </EditableText>
            </div>
            <div style={{ display: !hovered ? 'block' : 'none' }}>
              <span>{value1}</span>
            </div>
          </div>
          <div className="col-3">
            <EditableText
              value={value2}
              confirm={(value) => {
                this.setState({
                  value2: value,
                });
              }}
              cancel={v => {
                this.setState({
                  value2: v,
                });
              }}
              Popup={{
                title: 'Some title',
                actions: () => {
                  return (
                    <Button
                      className="btn-square _conflower-blue _filled"
                      onClick={() => {
                        this.editableTextRef && this.editableTextRef.handleConfirm();
                      }}
                    >
                      Custom confirm
                    </Button>
                  )
                },
              }}
              innerRef={el => { this.editableTextRef = el; }}
            >
              <div>Are you sure you want to change value?</div>
            </EditableText>
          </div>
        </div>
        <h5 id="example-amount">Example with Amount</h5>
        <div className="row example">
          <div className="col-3">
            <EditableText
              value={value3}
              type="amount"
              confirm={(value) => {
                this.setState({
                  value3: value,
                });
              }}
              cancel={v => {
                this.setState({
                  value3: v,
                });
              }}
              Popup={{
                title: 'Some title',
                actions: () => {
                  return (
                    <Button
                      className="btn-square _conflower-blue _filled"
                      onClick={() => {
                        this.editableTextRef && this.editableTextRef.handleConfirm();
                      }}
                    >
                      Custom confirm
                    </Button>
                  )
                },
              }}
              innerRef={el => { this.editableTextRef = el; }}
            >
              <div>Are you sure you want to change value?</div>
            </EditableText>
          </div>
        </div>
        <h5 id="example-date">Example with Date</h5>
        <div className="row example">
          <div className="col-3">
            <EditableText
              value={value4}
              type="date"
              confirm={(value) => {
                this.setState({
                  value4: value,
                });
              }}
              cancel={v => {
                this.setState({
                  value3: v,
                });
              }}
              Popup={{
                title: 'Some title',
                actions: () => {
                  return (
                    <Button
                      className="btn-square _conflower-blue _filled"
                      onClick={() => {
                        this.editableTextRef && this.editableTextRef.handleConfirm();
                      }}
                    >
                      Custom confirm
                    </Button>
                  )
                },
              }}
              innerRef={el => { this.editableTextRef = el; }}
            >
              <div>Are you sure you want to change value?</div>
            </EditableText>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this EditableText with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { EditableText } from 'factor';
                import 'factor/src/scss/_editable-text.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      value: 'some value',
                    };
                  }

                  render() {
                    const {
                      value,
                    } = this.state;

                    return (
                      <EditableText
                        value={value}
                        Popup={{
                          title: 'Edit text',
                          remember: true,
                          storageKey: 'editable_text',
                        }}
                        confirm={(value) => {
                          this.setState({
                            value: value,
                          });
                        }}
                      >
                        <div>Are you sure you want to change value?</div>
                      </EditableText>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='required'>value</td>
                <td>string | number</td>
                <td>none</td>
                <td>Editable value</td>
              </tr>
              <tr>
                <td>type</td>
                <td>string</td>
                <td>text</td>
                <td>Possible types: "amount", "date"</td>
              </tr>
              <tr>
                <td>confirm</td>
                <td>function(value, beforeEditValue)</td>
                <td>none</td>
                <td>Confirm callback</td>
              </tr>
              <tr>
                <td>cancel</td>
                <td>function(beforeEditValue)</td>
                <td>none</td>
                <td>Cancel callback</td>
              </tr>
              <tr>
                <td>innerRef</td>
                <td>function(componentInstance)</td>
                <td>none</td>
                <td>Callback for getting component instance</td>
              </tr>
              <tr>
                <td>Popup</td>
                <td>
                  {'{'}
                  <br />
                  title: string; Popup title
                  <br />
                  remember: boolean; Show remember question
                  <br />
                  storageKey: string; Key for saving answer
                  <br />
                  appendTo: HTMLElement; Append popup to this element
                  <br />
                  action: function(): JSX; Custom actions
                  <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Popup options</td>
              </tr>
              <tr>
                <td>onChange</td>
                <td>(value) => formattedValue</td>
                <td>none</td>
                <td>Callback for formatting input value</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
