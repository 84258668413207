import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-json';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import './highlight/styles.scss';

import React, { PureComponent, createRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import htmlFormat from './highlight/utils';
import { CopyToClipboardButton } from './CopyToClipboardButton';

Prism.plugins.NormalizeWhitespace.setDefaults({
  'remove-trailing': true,
  'remove-indent': true,
  'left-trim': true,
  'right-trim': true,
  'indent': 0,
  'remove-initial-line-feed': false,
  'tabs-to-spaces': 4,
  'spaces-to-tabs': 4,
});

export class Highlight extends PureComponent {
  _domRef = createRef();

  _handleRef = (node) => {
    this._domRef = node;
  };

  _highlight() {
    Prism.highlightElement(this._domRef);
  };

  componentDidMount() {
    this._highlight();
  }

  componentDidUpdate() {
    this._highlight();
  }

  render() {
    const {
      children,
      code = 'html',
      className = 'language-markup',
    } = this.props;

    let rendered;

    switch (code) {
      case 'js': {
        rendered = children;
        break;
      }
      default: {
        rendered = htmlFormat(renderToStaticMarkup(children));
      }
    }

    return (
      <div className="highlight highlight__wrapper">
        <pre className={[className, 'line-numbers'].join(' ')}>
          <code ref={this._handleRef}>
            {rendered}
          </code>
        </pre>
        <div className="highlight__copy-button">
          <CopyToClipboardButton code={children}/>
        </div>
      </div>
    );
  }
}
