import { Highlight } from '../../demoComponents/Highlight';
import React from 'react';

export const ButtonMarkupRow = ({component, text}) => {
  return (
    <div className="row align-items-center">
      <div className="col-7">
        <Highlight>{component}</Highlight>
      </div>
      <div className="col-5">
        {text}
      </div>
    </div>
  )
};
