import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import {
  Button,
  Checkbox,
  Dialog,
  DialogButtons,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogScrollableContent,
} from 'factor';
import { range } from 'lodash';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import './dialogPage/style.scss';

export class DialogPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      open2: false,
      askAgain: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  handleOpen = (field) => {
    this.setState({
      [field]: true,
    });
  };

  handleClose = (field) => {
    this.setState({
      [field]: false,
    });
  };

  render() {
    const { open, open2 } = this.state;
    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Dialog"
        className="page-dialog"
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <Button className="btn-square _conflower-blue" onClick={() => this.handleOpen('open')}>Open</Button>
          </div>
          <div className="col-6">
            <Button className="btn-square _conflower-blue" onClick={() => this.handleOpen('open2')}>Open</Button>
          </div>
          <Dialog
            open={open}
            onClickOutside={this.handleClose.bind(this, 'open')}
            handleEnterKeyEvent={() => window.alert('Enter keydown event action')}
          >
            <DialogContent>
              <h5>Delete Adding metrics?</h5>
              <p>Are you sure you want to delete Adding metrics?</p>
              <div className="d-flex justify-content-between">
                <Checkbox
                  label="Don't ask me again"
                  size="sm"
                  checked={this.state.askAgain}
                  onChange={(v) => this.setState({
                    askAgain: v
                  })}
                />
                <DialogButtons
                  buttons={[{
                    title: 'Cancel',
                    handler: this.handleClose.bind(this, 'open'),
                  }, {
                    className: '_filled',
                    title: 'Apply',
                    handler: this.handleClose.bind(this, 'open'),
                  }]}
                />
              </div>
            </DialogContent>
          </Dialog>
          <Dialog open={open2} onClickOutside={this.handleClose.bind(this, 'open2')}>
            <DialogHeader>
              <h5>Edit Summary Metrics</h5>
              <p>Choose what metrcis to include</p>
            </DialogHeader>
            <DialogScrollableContent>
              {range(0, 20).map((num) => {
                return (
                  <div key={num} className="mb-1">
                    <Checkbox label={`Some Item ${num}`}/>
                  </div>
                )
              })}
            </DialogScrollableContent>
            <DialogFooter>
              <DialogButtons
                buttons={[{
                  title: 'Cancel',
                  handler: this.handleClose.bind(this, 'open2'),
                }, {
                  className: '_filled',
                  title: 'Apply',
                  handler: this.handleClose.bind(this, 'open2'),
                }]}
              />
            </DialogFooter>
          </Dialog>
        </div>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this the Dialog with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Dialog, DialogContent, DialogButtons } from 'factor';
                import 'factor/src/scss/_dialog.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      open: false,
                    };
                  }

                  render() {
                    const {
                      open,
                    } = this.state;

                    return (
                      <Dialog open={open}>
                        <DialogContent>
                          <h5 className="title-card">Delete Adding metrics?</h5>
                          <p>Are you sure you want to delete Adding metrics?</p>
                          <DialogButtons
                            buttons={[{
                              title: 'Cancel',
                              handler: this.handleClose,
                            }, {
                              className: '_filled',
                              title: 'Apply',
                              handler: this.handleClose,
                            }]}
                          />
                        </DialogContent>
                      </Dialog>
                    );
                  }
                }
              `
            }
          </Highlight>
          <p>Or for using the Dialog with Scrollable content use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Dialog, DialogScrollableContent, DialogButtons, DialogHeader, DialogFooter } from 'factor';
                import 'factor/src/scss/_dialog.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      open: false,
                    };
                  }

                  render() {
                    const {
                      open,
                    } = this.state;

                    return (
                      <Dialog open={open2}>
                        <DialogHeader>
                          <h5 className="title-card">Edit Summary Metrics</h5>
                          <h6 className="title-card-subtitle">Choose what metrcis to include</h6>
                        </DialogHeader>
                        <DialogScrollableContent>
                          ... your content here
                        </DialogScrollableContent>
                        <DialogFooter>
                          <DialogButtons
                            buttons={[{
                              title: 'Cancel',
                              handler: this.handleClose,
                            }, {
                              className: '_filled',
                              title: 'Apply',
                              handler: this.handleClose,
                            }]}
                          />
                        </DialogFooter>
                      </Dialog>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="required">open</td>
              <td>boolean</td>
              <td>false</td>
              <td>Dialog opened</td>
            </tr>
            <tr>
              <td>onClickOutside</td>
              <td>function</td>
              <td>none</td>
              <td>Callback agter click outside content</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Content helpers</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>DialogHeader</td>
              <td>Wrapper for dialog header</td>
            </tr>
            <tr>
              <td>DialogContent</td>
              <td>Wrapper for dialog content</td>
            </tr>
            <tr>
              <td>DialogFooter</td>
              <td>Wrapper for dialog footer</td>
            </tr>
            <tr>
              <td>DialogScrollableContent</td>
              <td>Scrollable content wrapper for dialog</td>
            </tr>
            <tr>
              <td>DialogButtons</td>
              <td>Action buttons for dialog</td>
            </tr>
            <tr>
              <th colSpan={2}>Params</th>
            </tr>
            <tr>
              <td>buttons</td>
              <td>
                <code>[
                  {'{'}
                  label: string,
                  handler: function
                  {'}'}
                  ]
                </code>
              </td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
