import React from 'react';
import { ContextMenu, ContextMenuList, ContextMenuListDivider, ContextMenuListItem, Icon } from 'factor';

export const Example1 = (props) => {
  return (
    <ContextMenu {...props}>
      <ContextMenuList>
        <ContextMenuListItem title={true}>
          Title
        </ContextMenuListItem>
        <ContextMenuListItem>
          Item 1
        </ContextMenuListItem>
        <ContextMenuListItem>
          Item 2
        </ContextMenuListItem>
        <ContextMenuListDivider />
        <ContextMenuListItem>
          Item 3
        </ContextMenuListItem>
        <ContextMenuListItem>
          Item 4
        </ContextMenuListItem>
      </ContextMenuList>
    </ContextMenu>
  )
};

export const Example2 = (props) => {
  return (
    <ContextMenu {...props}>
      <ContextMenuList>
        <ContextMenuListItem title={true}>
          You can download
        </ContextMenuListItem>
        <ContextMenuListItem>
          <div className="d-flex align-items-center list-item">
            <Icon name="XLS" />
            <div className="list-item__title">XLS</div>
          </div>
        </ContextMenuListItem>
        <ContextMenuListItem>
          <div className="d-flex align-items-center list-item">
            <Icon name="CSV" />
            <div className="list-item__title">CSV</div>
          </div>
        </ContextMenuListItem>
      </ContextMenuList>
    </ContextMenu>
  );
};
