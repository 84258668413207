import React, { Component } from 'react';
import './logoPage/logo.scss';
import { Logo } from 'factor';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartialHeader } from '../demoComponents/PagePartialHeader';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';

export class LogoPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example'},
    { title: 'React usage', link: '#react'},
  ];

  render() {
    return (
      <article className="page">
        <PagePartialHeader
          title="Logo"
          navigation={this.pageNavigation}
        />
        <div className="page__content" id="example">
          <h5>Example</h5>
          <div className="example row d-flex justify-content-between">
              <div className="col-3 p-3"><Logo/></div>
              <div className="col-3 p-3"><Logo type={'black'}/></div>
              <div className="col-3 bg-dark p-3"><Logo type={'white'}/></div>
          </div>
          <br/>
          <PagePartialUsageBlock
            subTitle="(react only)"
            id="react"
          >
            <p>For using this logo with React use this</p>
            <Highlight className="language-jsx" code="js">
              {
                `
                      import { Logo } from 'factor';
                      import 'factor/src/scss/_logo.scss';

                      class YourPage extends React.Component {
                        render() {
                          return (
                            <Logo />
                          );
                        }
                      }
                      `
              }
            </Highlight>
          </PagePartialUsageBlock>
          <PagePartialUsageBlock
            title={<h6 className="header-block__title">Params</h6>}
          >
            <table className="props-table">
              <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Button class name</td>
              </tr>
              <tr>
                <td>type</td>
                <td>enum: white | black</td>
                <td>none</td>
                <td>Logo color</td>
              </tr>
              </tbody>
            </table>
          </PagePartialUsageBlock>
        </div>
      </article>
    );
  }
}
