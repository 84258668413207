import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Highlight } from '../demoComponents/Highlight';

export class FaviconPage extends Component {
  render() {
    return (
      <PagePartial
        title="Favicon"
      >
        <h4>Requirements</h4>
        <p>For adding favicon to website download <a className="link" href="/download/favicon.zip" download>favicon archive</a>.</p>
        <p>Unzip it to your public directory.</p>
        <p>Add to your html next</p>
        <Highlight>
          <link rel="shortcut icon" href="%ASSETS%/favicon.ico"/>
          <link rel="manifest" href="%ASSETS%/manifest.json"/>
          <link rel="apple-touch-icon" sizes="144x144" href="%ASSETS%/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="%ASSETS%/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="%ASSETS%/favicon-16x16.png"/>
          <link rel="mask-icon" href="%ASSETS%/safari-pinned-tab.svg" color="#5bbad5"/>
        </Highlight>
        <p>Where <code className="inline red">%ASSETS%</code> your path to files. Remove it if you put the files to
          the root folder near with your html.
        </p>
        <p>Edit <code className="inline red">manifest.json</code>. Write your application&nbsp;
          <code className="inline red">name</code> and <code className="inline red">short_name</code>.
        </p>
      </PagePartial>
    );
  }
}