import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class LinkPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'Usage', link: '#usage' },
  ];

  render() {
    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Links"
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-4"><Link to="/links" className="link">Default Link</Link></div>
          <div className="col-4"><Link to="/links" className="link link_orange">Orange Link</Link></div>
          <div className="col-4"><Link to="/links" className="link link_persimmon">Persimmon Link</Link></div>
        </div>
        <PagePartialUsageBlock
          id="usage"
        >
          <Highlight>
            <a href="/" className="link">Default Link</a>
            <a href="/" className="link link_orange">Orange Link</a>
            <a href="/" className="link link_persimmon">Persimmon Link</a>
          </Highlight>
          <p>For using the Link style just include <code className="inline red">link.scss</code> to your project</p>
          <p>Using with scss</p>
          <Highlight className="language-css">
            {
              `@import "~factor/src/scss/link";`
            }
          </Highlight>
          <p>Using with js</p>
          <Highlight className="language-jsx" code="js">
            {
              `import 'factor/src/scss/_link.scss';`
            }
          </Highlight>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
