import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Block, BlockRemove } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class BlockPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  render() {
    return (
      <PagePartial
        title="Block"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">Block</h4>
            <Block>
              <BlockRemove />
              <div>Some Content</div>
            </Block>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Block, BlockRemove } from 'factor';

                class YourPage extends React.Component {
                  render() {
                    return (
                      <div className="row example">
                        <div className="col-6">
                          <h4 className="title-card mb-2">Block</h4>
                          <Block>
                            <BlockRemove />
                            <div>Some Content</div>
                          </Block>
                        </div>
                      </div>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Block Props</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>none</td>
              <td>custom css class</td>
            </tr>
            <tr>
              <td>children</td>
              <td>element</td>
              <td>none</td>
              <td>Block content</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
