import React, { Component } from 'react';
import { TextField, TextFieldWithIcon, URLTextField } from 'factor';
import { validateURL } from '../utils/validation';
import { PagePartial } from '../demoComponents/PagePartial';
import { emptyValidation, emailValidation, FormExample } from './textFieldPage/FormExample';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class TextFieldPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myFieldValue: 'some value test',
      search: '',
      deleteText: '',
      textFieldWithBorderValue: '',
      newTextFieldValue: '',
      newIconTextFieldValue: '',
      highTextareaValue: '',
      URLTextFieldValue: '',
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example1' },
    { title: 'Example with Icon', link: '#example3' },
    { title: 'Example only with border', link: '#example4' },
    { title: 'Example with form', link: '#example2' },
    { title: 'React usage', link: '#react' },
    { title: 'React usage with validation', link: '#react2' },
    { title: 'Html usage', link: '#html' },
  ];

  myFieldValidation = [
    emptyValidation,
    {
      func: (value, input, rule) => {
        const v = value && value.trim();

        return !v.includes('test');
      },
      error: () => {
        return `This filed must not contain 'test' text!`;
      },
    },
  ];

  emailValidation = [
    emptyValidation,
    emailValidation,
  ];

  render() {
    const {
      search,
      deleteText,
      textFieldWithBorderValue,
    } = this.state;

    return (
      <PagePartial
        title="Text fields"
        navigation={this.pageNavigation}
      >
        <h5 id="example1">Example</h5>
        <div className="example row">
          <div className="col-6 mb-4">
            <TextField
              name="myfield"
              label="My text field"
              validationRules={this.myFieldValidation}
              onChange={(v) => this.setState({
                myFieldValue: v,
              })}
              placeholder="placeholder"
              value={this.state.myFieldValue}
              validateOnInit={true}
              noBorder
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="password"
              label="Password"
              type="password"
              value={this.state.value}
              helperText="Some helper text"
              noBorder
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="email"
              label="Email"
              validationRules={this.emailValidation}
              helperText="Some text until no error"
              noBorder
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="Company"
              label="Company"
              disabled={true}
              noBorder
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="amount"
              label="Amount"
              type="amount"
              value={this.state.value2}
              onChange={value => this.setState({value2: value})}
              helperText="Some helper text"
              noBorder
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="newTextField"
              label="Search with border"
              value={this.state.newTextFieldValue}
              onChange={value => this.setState({newTextFieldValue: value})}
              validationRules={[{...emptyValidation, title: 'TextField'}]}
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              name="newIconTextField"
              label="Search with Icon"
              value={this.state.newIconTextFieldValue}
              onChange={value => this.setState({newIconTextFieldValue: value})}
              iconName="Search"
              isTextarea
            />
          </div>
          <div className="col-6 mb-4">
            <URLTextField
              label="URL text field"
              validationRules={[validateURL('URL text field')]}
              onChange={(value) => {
                this.setState({
                  URLTextFieldValue: value,
                });
              }}
              value={this.state.URLTextFieldValue}
            />
          </div>
          <div className="col-6 mb-4">
            <TextField
              className="text-field-page__high-textarea"
              name="highTextareaValue"
              label="Textarea with copy button"
              placeholder="Type something and hover textarea to see copy button"
              value={this.state.highTextareaValue}
              onChange={value => this.setState({highTextareaValue: value})}
              isTextarea
              hasCopy
            />
          </div>
        </div>
        <h5 id="example3">Old input with icon example</h5>
        <div className="row example">
          <div className="col-6">
            <TextFieldWithIcon
              iconName="Search"
              placeholder="Search"
              onChange={(e) => {
                this.setState({
                  search: e.target.value,
                })
              }}
              value={search}
            />
          </div>
          <div className="col-6">
            <TextFieldWithIcon
              iconName="Delete"
              placeholder="Some placeholder"
              onChange={(e) => {
                this.setState({
                  deleteText: e.target.value,
                })
              }}
              value={deleteText}
            />
          </div>
        </div>
        <h5 id="example4">Old input without icon but with border</h5>
        <div className="row example">
          <div className="col-6">
            <TextFieldWithIcon
              placeholder="Search"
              onChange={(e) => {
                this.setState({
                  textFieldWithBorderValue: e.target.value,
                })
              }}
              value={textFieldWithBorderValue}
            />
          </div>
        </div>
        <h5 id="example2">Example Form component with validation</h5>
        <FormExample />
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this TextField with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { TextField, Form } from 'factor';
                import 'factor/src/scss/_text-field.scss';
                import 'factor/src/scss/_form.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      value: 'some value',
                      search: '',
                    };
                  }

                  render() {
                    const {
                      value,
                      search,
                    } = this.state;

                    return (
                      <Form title="Form title">
                        <TextField
                          name="myfield"
                          label="My text field"
                          validationRules={this.myFieldValidation}
                          onChange={( v ) => this.setState({
                            myFieldValue: v,
                          })}
                          value={value}
                        />,
                        <TextFieldWithIcon
                          iconName="Search"
                          onChange={(e) => {
                            this.setState({
                              search: e.target.value,
                            })
                          }}
                          value={search}
                        />
                      </Form>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <h5 id="react2">Usage with form validation</h5>
        <Highlight className="language-jsx" code="js">
          {
            `
            import React, { Component } from 'react';
            import { Button, TextField } from 'factor';

            const emptyValidation = {
              func: (value, input, rule) => {
                return !!(value && value.trim());
              },
              error: (name, rule) => {
                return \`\${name} must be filled!\`;
              },
            };

            const emailValidation = {
              func: (value) => {
                return EMAIL_REG.test(value);
              },
              error: (name, rule) => {
                return \`\${rule.title} must be a valid email address!\`
              },
              title: 'Email',
            };

            const EMAIL_REG = /(.+)@(.+){2,}\\.(.+){2,}/;

            class FormExample extends Component {
              constructor(props) {
                super(props);

                this.state = {
                  login: '',
                  email: '',
                  valid: {
                    login: false,
                    email: false,
                  },
                  isFormValid: false,
                };
              }

              isFormValid(isValid, name) {
                this.setState((state) => {
                  return {
                    ...state,
                    valid: {
                      ...state.valid,
                      [name]: isValid,
                    }
                  };
                }, () => {
                  const { valid } = this.state;
                  if (valid.login && valid.email) {
                    this.setState({
                      isFormValid: true,
                    });
                  } else {
                    this.setState({
                      isFormValid: false,
                    });
                  }
                });
              }

              render() {
                return (
                  <form className="example row" onSubmit={(e) => e.preventDefault()}>
                    <div className="col-6 mb-2">
                      <TextField
                        label="Login"
                        name="form_login"
                        type="text"
                        validationRules={[
                          emptyValidation,
                        ]}
                        onChange={(v) => this.setState({
                          login: v,
                        })}
                        onValidate={(valid) => this.isFormValid(valid, 'login')}
                        value={this.state.login}
                      />
                    </div>
                    <div className="w-100"></div>
                    <div className="col-6 mb-2">
                      <TextField
                        label="Email"
                        name="email"
                        type="text"
                        validationRules={[
                          emptyValidation,
                          emailValidation,
                        ]}
                        onChange={(v) => this.setState({
                          email: v,
                        })}
                        onValidate={(valid) => this.isFormValid(valid, 'email')}
                        value={this.state.email}
                      />
                    </div>
                    <div className="w-100"></div>
                    <div className="col-6">
                      <Button
                        className="btn-1 _fountain-blue"
                        disabled={!this.state.isFormValid}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                );
              }
            }
            `
          }
        </Highlight>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>label</td>
                <td>string</td>
                <td>none</td>
                <td>Input label</td>
              </tr>
              <tr>
                <td>type</td>
                <td>string</td>
                <td>none</td>
                <td>Input typel</td>
              </tr>
              <tr>
                <td>name</td>
                <td>string</td>
                <td>none</td>
                <td>Input name</td>
              </tr>
              <tr>
                <td>placeholder</td>
                <td>string</td>
                <td>none</td>
                <td>Input placeholder</td>
              </tr>
              <tr>
                <td>helperText</td>
                <td>string</td>
                <td>none</td>
                <td>Text under input</td>
              </tr>
              <tr>
                <td>value</td>
                <td>string</td>
                <td>none</td>
                <td>Input value</td>
              </tr>
              <tr>
                <td>validationRules</td>
                <td>
                  Array of objects <br />
                  {'{'} <br />
                  func: function(value, target, rule) (must return boolean, required), <br />
                  error: function(name, rule) (must return string error), <br />
                  {'}'}
                </td>
                <td>[]</td>
                <td>Validation rules</td>
              </tr>
              <tr>
                <td>validateOnInit</td>
                <td>boolean</td>
                <td>false</td>
                <td>Validate immediately</td>
              </tr>
              <tr>
                <td>reset</td>
                <td>boolean</td>
                <td>false</td>
                <td>Add reset button</td>
              </tr>
              <tr>
                <td>onChange</td>
                <td>function(value)</td>
                <td>none</td>
                <td>Change value function</td>
              </tr>
              <tr>
                <td>onValidate</td>
                <td>function(isValid, target)</td>
                <td>none</td>
                <td>Return true or false after validate</td>
              </tr>
              <tr>
                <td>onFocus</td>
                <td>function(event)</td>
                <td>none</td>
                <td>Callback after input focused</td>
              </tr>
              <tr>
                <td>onBlur</td>
                <td>function(event)</td>
                <td>none</td>
                <td>Callback after focus lost</td>
              </tr>
              <tr>
                <td>inputRef</td>
                <td>function(componentInstance)</td>
                <td>none</td>
                <td>Callback for getting input component instance</td>
              </tr>
              <tr>
                <td>hasCopy</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>Copy TextField text button. Appears only in textarea with border without reset button.</td>
              </tr>
              <tr>
                <td>noBorder</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>Turn on old style of TextField - with only bottom border.</td>
              </tr>
              <tr>
                <td>disabled</td>
                <td>boolean</td>
                <td>false</td>
                <td>Disables the TextField</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          subTitle="(html only)"
          id="html"
        >
          <p>For using this text field you need to import <code className="inline red">text-field.scss</code> in your
            scss file.
          </p>
          <Highlight className="language-css">
            {
              `@import "~factor/src/scss/text-field";`
            }
          </Highlight>
          <p>Inside your js file use this code</p>
          <Highlight className="language-js" code="js">
            {
              `
                  /* You can import style here instead of scss file
                  * import 'factor/src/scss/_text-field.scss';
                  */

                  import { createTextField } from 'factor';

                  const container = document.querySelector('.js-field');
                  createTextField(container, options);
                  `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Arguments</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>container (required)</td>
                <td>HTMLElement</td>
                <td>TextField will attach to this container</td>
              </tr>
              <tr>
                <th colSpan={3}>Options</th>
              </tr>
              <tr>
                <td>label</td>
                <td>string</td>
                <td>Input label</td>
              </tr>
              <tr>
                <td>type</td>
                <td>string</td>
                <td>Input type</td>
              </tr>
              <tr>
                <td>name</td>
                <td>string</td>
                <td>Input name</td>
              </tr>
              <tr>
                <td>helperText</td>
                <td>string</td>
                <td>Text under input</td>
              </tr>
              <tr>
                <td>validationRules</td>
                <td>
                  Array of objects <br />
                  {'{'} <br />
                  func: function(value, target, rule) (must return boolean, required), <br />
                  error: function(name, rule) (must return string error), <br />
                  {'}'}
                </td>
                <td>Validation rules</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
