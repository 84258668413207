import { HomePage } from '../pages/HomePage';
import { LogoPage } from '../pages/LogoPage';
import { ButtonsPage } from '../pages/ButtonsPage';
import { SwitchPage } from '../pages/SwitchPage';
import { TumblerPage } from '../pages/TumblerPage';
import { DatesPickerPage } from '../pages/DatesPickerPage';
import { SelectPage } from '../pages/SelectPage';
import { TextFieldPage } from '../pages/TextFieldPage';
import { ColorPage } from '../pages/ColorPage';
import { CheckboxPage } from '../pages/CheckboxPage';
import { ButtonsGroupPage } from '../pages/ButtonsGroupPage';
import { FaviconPage } from '../pages/FaviconPage';
import { TypographyPage } from '../pages/TypographyPage';
import { CardsPage } from '../pages/CardsPage';
import { SnackbarPage } from '../pages/SnackbarPage';
import { IconsPage } from '../pages/IconsPage';
import { DialogPage } from '../pages/DialogPage';
import { LinkPage } from '../pages/LinkPage';
import { NavigationPage } from '../pages/NavigationPage';
import { TabsPage } from '../pages/TabsPage';
import { PaginationPage } from '../pages/PaginationPage';
import { TablePage } from '../pages/TablePage';
import { TooltipPage } from '../pages/TooltipPage';
import { CollapsePage } from '../pages/CollapsePage';
import { ContextMenuPage } from '../pages/ContextMenuPage';
import { EditableTextPage } from '../pages/EditableTextPage';
import { TimePickerPage } from '../pages/TimePickerPage';
import { LayoutPage } from '../pages/LayoutPage';
import { OpenerPage } from '../pages/OpenerPage';
import { SelectListPage } from '../pages/SelectListPage';
import { BlockPage } from '../pages/BlockPage';
import { CollapsibleBlockPage } from '../pages/CollapsibleBlockPage';
import { ParamsCardPage } from '../pages/ParamsCardPage';
import { ActionMenuPage } from '../pages/ActionMenuPage';
import { ActionSelectPage } from '../pages/ActionSelectPage';
import { ActionButtonPage } from '../pages/ActionButtonPage';
import { ImageCardPage } from '../pages/ImageCardPage';
import { CustomerSelectPage } from '../pages/CustomerSelectPage';
import { ProgressCirclesPage } from '../pages/ProgressCirclesPage';
import { FileUploaderPage } from '../pages/FileUploaderPage';

const routes = [
  {
    title: 'Getting started',
    path: '/',
    exact: true,
    component: HomePage,
  },
  {
    title: 'Color',
    path: '/color',
    component: ColorPage,
  },
  {
    title: 'Typography',
    path: '/typography',
    component: TypographyPage,
  },
  {
    title: 'Logo',
    path: '/logo',
    component: LogoPage,
  },
  {
    title: 'Favicon',
    path: '/favicon',
    exact: true,
    component: FaviconPage,
  },
  {
    title: 'Navigation Bar',
    path: '/navigation',
    component: NavigationPage,
  },
  {
    title: 'Buttons',
    path: '/buttons',
    component: ButtonsPage,
  },
  {
    title: 'Links',
    path: '/links',
    component: LinkPage,
  },
  {
    title: 'Text fields',
    path: '/textfields',
    component: TextFieldPage,
  },
  {
    title: 'Editable Text',
    path: '/editable-text',
    component: EditableTextPage,
  },
  {
    title: 'Checkbox',
    path: '/checkbox',
    component: CheckboxPage,
  },
  {
    title: 'Switch',
    path: '/switch',
    component: SwitchPage,
  },
  {
    title: 'Tumbler',
    path: '/tumbler',
    component: TumblerPage,
  },
  {
    title: 'Buttons groups',
    path: '/buttonsgroup',
    component: ButtonsGroupPage,
  },
  {
    title: 'Tabs',
    path: '/tabs',
    component: TabsPage,
  },
  {
    title: 'Collapse',
    path: '/collapse',
    component: CollapsePage,
  },
  {
    title: 'Pagination',
    path: '/pagination',
    component: PaginationPage,
  },
  {
    title: 'Table',
    path: '/table',
    component: TablePage,
  },
  {
    title: 'Cards',
    path: '/cards',
    component: CardsPage,
  },
  {
    title: 'Date Picker',
    path: '/datepicker',
    component: DatesPickerPage,
  },
  {
    title: 'Time Picker',
    path: '/timepicker',
    component: TimePickerPage,
  },
  {
    title: 'Select',
    path: '/select',
    component: SelectPage,
  },
  {
    title: 'SelectList',
    path: '/select-list',
    component: SelectListPage,
  },
  {
    title: 'Tooltip',
    path: '/tooltip',
    component: TooltipPage,
  },
  {
    title: 'Snackbar',
    path: '/snackbar',
    component: SnackbarPage,
  },
  {
    title: 'Dialog',
    path: '/dialog',
    component: DialogPage,
  },
  {
    title: 'Context menu',
    path: '/context-menu',
    component: ContextMenuPage,
  },
  {
    title: 'Layout',
    path: '/layout',
    component: LayoutPage,
  },
  {
    title: 'Opener',
    path: '/opener',
    component: OpenerPage,
  },
  {
    title: 'Icons',
    path: '/icons',
    component: IconsPage,
  },
  {
    title: 'Block',
    path: '/block',
    component: BlockPage,
  },
  {
    title: 'CollapsibleBlock',
    path: '/colblock',
    component: CollapsibleBlockPage,
  },
  {
    title: 'ParamsCard',
    path: '/params-card',
    component: ParamsCardPage,
  },
  {
    title: 'ActionMenu',
    path: '/action-menu',
    component: ActionMenuPage,
  },
  {
    title: 'ActionSelect',
    path: '/action-select',
    component: ActionSelectPage,
  },
  {
    title: 'ActionButton',
    path: '/action-button',
    component: ActionButtonPage,
  },
  {
    title: 'ImageCard',
    path: '/image-card',
    component: ImageCardPage,
  },
  {
    title: 'CustomerSelect',
    path: '/customer-select',
    component: CustomerSelectPage,
  },
  {
    title: 'Progress Circles',
    path: '/progress-circles',
    component: ProgressCirclesPage,
  },
  {
    title: 'File Uploader',
    path: '/file-uploader',
    component: FileUploaderPage,
  },
];

export default routes;
