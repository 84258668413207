import React from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { TimePicker } from 'factor';
import moment from 'moment';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class TimePickerPage extends React.PureComponent {
  state = {
    time: moment(),
  };
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  handleChange = (time) => {
    this.setState({
      time: time,
    });
  };

  render() {
    const { time } = this.state;
    return (
      <PagePartial
        title="Time Picker"
        navigation={this.pageNavigation}
        className="timepicker-page"
      >
        <h5 id="example">Example</h5>
        <div className="example row align-items-end">
          <div className="col-6">
            <div className="row mb-2">
              {time.format('hh:mm:ss A')}
            </div>
            <div className="row">
              <TimePicker
                value={time.valueOf()}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Table with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
              import { TimePicker } from 'factor';

              class YourPage extends React.Component {
                constructor(props) {
                  super(props);

                  this.state = {
                    time: moment(),
                  };
                }

                handleChange = (time) => {
                    this.setState({
                        time: time,
                    });
                };

                render() {
                  return (
                    <TimePicker
                        openTo={'hours'}
                        views={['hours', 'minutes', 'seconds']}
                        value={time.valueOf()}
                        onChange={this.handleChange}
                    />
                  );
                }
              }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={'required'}>value</th>
                <th>number</th>
                <th>none</th>
                <th>Time value</th>
              </tr>
              <tr>
                <th className={'required'}>onChange</th>
                <th>func (date: moment)</th>
                <th>none</th>
                <th>Onchange callback</th>
              </tr>
              <tr>
                <th>openTo</th>
                <th>hours | minutes | seconds</th>
                <th>hours</th>
                <th>First view need to show</th>
              </tr>
              <tr>
                <th>views</th>
                <th>Array of [hours, minutes, seconds]</th>
                <th>[hours, minutes]</th>
                <th>Views to show</th>
              </tr>
              <tr>
                <th>minutesStep</th>
                <th>number</th>
                <th>1</th>
                <th>Minutes interval</th>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
