import React, { Component } from 'react';
import { Opener, Button, Icon, SelectList } from 'factor';

import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class OpenerPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  options = [
    {
      label: 'fruits',
      isGroupHeader: true,
      options: [
        { value: 'apple', label: 'Apple' },
        { value: 'pear', label: 'Pear' },
        { value: 'banana', label: 'Banana' },
        { value: 'apricot', label: 'Apricot' },
        { value: 'clementine', label: 'Clementine' },
        { value: 'grapefruit', label: 'Grapefruit' },
        { value: 'grape', label: 'Grape' },
      ],
    },
    {
      label: 'berries',
      isGroupHeader: true,
      options: [
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'blueberry', label: 'Blueberry' },
        { value: 'raspberry', label: 'Raspberry' },
      ],
    }
  ];

  render() {
    return (
      <PagePartial
        title="Opener"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">Opener</h4>
            <Opener
              btn={
                <Button className="btn-round _filled _conflower-blue">
                  Button
                  <span className="btn-content__icon">
                    <Icon name="Expand" />
                  </span>
                </Button>
              }
              content={
                <SelectList
                  show={true}
                  options={this.options}
                />
              }
            />
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React, { Component } from 'react';
                import { Opener, Button, Icon, SelectList } from 'factor';

                export class OpenerPage extends Component {
                  render() {
                    return (
                      <Opener
                        btn={
                          <Button className="btn-round _filled _conflower-blue">
                            Button
                            <span className="btn-content__icon">
                              <Icon name="Expand" />
                            </span>
                          </Button>
                        }
                        content={
                          <SelectList
                            show={true}
                            options={this.options}
                          />
                        }
                      />
                    )
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Opener Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Custom css class</td>
              </tr>
              <tr>
                <td>btnClassName</td>
                <td>string</td>
                <td>none</td>
                <td>Button custom css class</td>
              </tr>
              <tr>
                <td>contentClassName</td>
                <td>string</td>
                <td>none</td>
                <td>Content custom css class</td>
              </tr>
              <tr>
                <td>btn</td>
                <td>element</td>
                <td>undefined</td>
                <td>Button</td>
              </tr>
              <tr>
                <td>content</td>
                <td>element</td>
                <td>undefined</td>
                <td>Content</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
