import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../routes/index';
import './drawer/drawer.scss';

export const Drawer = (props) => (
  <nav className={`drawer ${props.static ? '_static' : ''}`}>
    <ul className="drawer__list">
      {routes
        .sort((a, b) => {
          if (a.title === b.title) {
            return 0;
          }

          if (a.title === 'Getting started') {
            return -1;
          }

          if (b.title === 'Getting started') {
            return 1;
          }

          if (a.title < b.title) {
            return -1;
          }

          if (a.title > b.title) {
            return 1;
          }

          return 0;
        })
        .map((route, index) => (
          <li className="drawer__list-item" key={index}>
            <NavLink
              className="drawer__link"
              exact={route.exact}
              activeClassName="active"
              to={route.path}
            >{route.title}</NavLink>
          </li>
        ))
      }
    </ul>
  </nav>
);

Drawer.propTypes = {
  static: PropTypes.bool,
};
