import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { FileUploader } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class FileUploaderPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  handleFileUpload = (files) => {
    window.alert(`File "${files[0].name}" uploaded.`);
  }

  render() {
    return (
      <PagePartial
        title="File Uploader"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-4">
            <FileUploader
              onFileUploaded={this.handleFileUpload}
              browseFileButtonTitle={'Upload Image'}
              tooltipParams={{
                label: 'Select images from your computer using dialog or drag and drop them here',
                labelMaxWidth: 300,
                position: 'top'
              }}
              notice={
                <>
                  * File size limit: 2Mb for JPG, PNG,
                  <br />
                  750 Kb for GIF
                </>
              }
            />
          </div>
        </div>

        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this component with React, use the following</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React from 'react';
                import { FileUploader } from 'factor';

                class YourPage extends React.Component {

                  render() {
                    return (<>
                      <FileUploader
                        onFileUploaded={this.handleFileUpload}
                        browseFileButtonTitle={'Upload Image'}
                        tooltipParams={{
                          label: 'Select images from your computer using dialog or drag and drop them here',
                          labelMaxWidth: 300,
                          position: 'top'
                        }}
                        notice={
                          <>
                            * File size limit: 2Mb for JPG, PNG,
                            <br />
                            750 Kb for GIF
                          </>
                        }
                      />
                    </>);
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">FileUploader Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td />
                <td>Custom css class(es)</td>
              </tr>
              <tr>
                <td>browseFileButtonTitle</td>
                <td>string</td>
                <td>'Upload'</td>
                <td>Browse File button text</td>
              </tr>
              <tr>
                <td>onFileUploaded</td>
                <td>function(files)</td>
                <td />
                <td>Callback after file(s) uploaded</td>
              </tr>
              <tr>
                <td>tooltipParams</td>
                <td style={{minWidth: '230px'}}>{`{`}<br />
                  &nbsp;&nbsp;label: string,<br />
                  &nbsp;&nbsp;labelMaxWidth: number,<br />
                  &nbsp;&nbsp;position: string<br />
                  {`}`}
                </td>
                <td />
                <td>
                  Params to be passed down to tooltip on Browse File button.
                  Contains label, labelMaxWidth and position.
                </td>
              </tr>
              <tr>
                <td>isMultuple</td>
                <td>boolean</td>
                <td />
                <td>Whether multiple files are allowed to be uploaded or not</td>
              </tr>
              <tr>
                <td>isError</td>
                <td>boolean</td>
                <td />
                <td>Whether there was an error in uploaded file</td>
              </tr>
              <tr>
                <td>exampleDataURL</td>
                <td>string</td>
                <td />
                <td>Link to sample file</td>
              </tr>
              <tr>
                <td>accept</td>
                <td>string</td>
                <td>'*'</td>
                <td>HTML5 file 'accept' attribute</td>
              </tr>
              <tr>
                <td>notice</td>
                <td>string/node</td>
                <td />
                <td>Notice to display below the upload button</td>
              </tr>
              <tr>
                <td>dragDropText</td>
                <td>string</td>
                <td>'Drag and Drop'</td>
                <td>Text to display informing drag and drop is allowed</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
