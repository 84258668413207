import React, { PureComponent } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Checkbox, Collapse } from 'factor';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';

const data = [
  {title: 'Product 1', id: 1},
  {title: 'Product 2', id: 2},
  {title: 'Product 3', id: 3},
  {title: 'Product 4', id: 4},
  {title: 'Product 5', id: 5},
];

export class CollapsePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: [1, 2],
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  handleCheckbox = (id, v) => {
    const {
      checkedItems,
    } = this.state;

    const index = checkedItems.indexOf(id);

    if (index !== -1 && !v) {
      checkedItems.splice(index, 1);
    } else if (index === -1 && v) {
      checkedItems.push(id);
    }

    this.setState({
      checkedItems: [
        ...checkedItems,
      ]
    });
  };

  render() {
    return (
      <PagePartial
        title="Collapse"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-6">
            <Collapse
              title={`Products (${this.state.checkedItems.length}/${data.length})`}
            >
              {data.map(item => {
                const checked = this.state.checkedItems.indexOf(item.id) !== -1;
                return <Checkbox key={item.id} label={item.title} checked={checked} onChange={v => this.handleCheckbox(item.id, v)}/>
              })}
            </Collapse>
          </div>
        </div>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Collapse with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
              import { Collapse } from 'factor';
              import 'factor/src/scss/_collapse.scss';

              class YourPage extends React.Component {
                render() {
                  return (
                    <Collapse
                      title="Title"
                    >
                      ... Content here
                    </Collapse>
                  );
                }
              }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>title</td>
              <td>string | element</td>
              <td>none</td>
              <td>Collapse title</td>
            </tr>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>none</td>
              <td>Additional class</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
