import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Checkbox } from 'factor';
import { CheckboxFolHtml } from '../demoComponents/CheckboxFolHtml';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class CheckboxPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rememberMe: false,
    };
  }

  navigationPage = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
    { title: 'Html usage', link: '#html' },
  ];

  render() {
    const cb1 = (
      <CheckboxFolHtml
        name="remember"
        onChange={(v) => {
          this.setState({ rememberMe: v })
        }}
        checked={this.state.rememberMe}
        label="Remember me"
      />
    );

    return (
      <PagePartial
        title="Checkbox"
        navigation={this.navigationPage}
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-4">
            <Checkbox
              name="remember"
              onChange={(v) => {
                this.setState({ rememberMe: v })
              }}
              checked={this.state.rememberMe}
              label="Remember me"
            />
          </div>
          <div className="col-4">
            <Checkbox label="Disabled" name="disabled" disabled={true}/>
          </div>
          <div className="col-4">
            <Checkbox label="Disabled checked" name="disabledChecked" checked={true} disabled={true}/>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
        >
          <p>For using Checkbox in react use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
              import { Checkbox } from 'factor';
              import 'factor/src/scss/_checkbox.scss';

              class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      rememberMe: true,
                    };
                  }

                  render() {
                    const {
                      rememberMe,
                    } = this.state;

                    return (
                      <div>
                        <Checkbox
                          checked={rememberMe}
                          label="Remember me"
                          name="rememberme"
                          onChange={v => this.setState({rememberMe: v})}
                        />
                        <Checkbox
                          label="Disabled"
                          size="sm"
                          name="disabled"
                          disabled={true}
                        />
                      </div>
                    );
                  }
                }

              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
          id="react"
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="required">label</td>
              <td>string</td>
              <td>none</td>
              <td>Input label</td>
            </tr>
            <tr>
              <td>name</td>
              <td>string</td>
              <td>none</td>
              <td>Input name and label for value</td>
            </tr>
            <tr>
              <td>checked</td>
              <td>boolean</td>
              <td>false</td>
              <td>Input checked/unchecked</td>
            </tr>
            <tr>
              <td>disabled</td>
              <td>boolean</td>
              <td>false</td>
              <td>Input disabled/enabled</td>
            </tr>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>none</td>
              <td>Additional class</td>
            </tr>
            <tr>
              <td>size</td>
              <td>enum: sm</td>
              <td>none</td>
              <td>Checkbox size</td>
            </tr>
            <tr>
              <td>onChange</td>
              <td>function(value)</td>
              <td>none</td>
              <td>Callback after value changed</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          subTitle="(html only)"
          id="html"
        >
          <p>For using checkbox you need to import <code className="inline red">checkbox.scss</code> in your scss file.
          </p>
          <Highlight className="language-css">
            {
              `@import "~factor/src/scss/checkbox";`
            }
          </Highlight>
          <p>Insert html</p>
          <Highlight>
            {cb1}
          </Highlight>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
