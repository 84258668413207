import React, { Component, Fragment } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Table, Select, ButtonCircle, Button, EditableText } from 'factor';
import './tablePage/styles.scss';
import { generateUsersTable, roles } from './tablePage/dataGenerator';
import { ServerTableEmulation } from './tablePage/ServerTableEmulation';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';
import { InfiniteScrollTableEmulation } from './tablePage/InfiniteScrollTableEmulation';
import { moveItem } from './tablePage/helpers';

export class TablePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paging: {
        activePage: 1,
        itemsCountPerPage: 10,
      },
      data: generateUsersTable(25),
      firstTableData: JSON.parse(JSON.stringify(this.table.data)).map((i, k) => ({
        ...i,
        confirm: (value) => {
          const { firstTableData } = this.state;
          firstTableData[k].role = value;
          this.setState({firstTableData});
        }
      })),
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  roles = roles.map(r => ({ label: r, value: r }));

  table = {
    header: [
      { label: 'Role', sortingKey: 'role', className: 'column2', draggableGroup: 'group1', tooltip: 'Role' },
      { label: 'Privileges', sortingKey: 'privileges', draggableGroup: 'group1' },
      {
        label: 'Created',
        sortingKey: 'created',
        compareFunc: (dir, a, b) => {
          const aArr = a.created.split('/');
          const bArr = b.created.split('/');
          const aDate = new Date(aArr[2], aArr[1], aArr[0]);
          const bDate = new Date(bArr[2], bArr[1], bArr[0]);

          if (dir === 'asc') {
            return +aDate - +bDate;
          } else {
            return +bDate - aDate;
          }
        },
      },
      { label: 'Users', sortingKey: 'users' },
      { label: 'Actions', sorting: false, tooltip: 'Actions' },
    ],
    body: [
      {
        key: 'role',
        className: 'column2',
        click: (data, field) => {
          return (
            <EditableText
              value={data.role}
              Popup={{
                title: 'Edit text',
                remember: true,
                storageKey: 'editable_text',
              }}
              confirm={data.confirm}
            >
              <div>Are you sure you want to change value?</div>
            </EditableText>
          )
        },
        isFocusOnClick: true,
        clickFocusRefPropName: 'inputRef',
      },
      { key: 'privileges' },
      { key: 'created' },
      { key: 'users' },
      {
        key: '_actions',
        className: '_actions',
        hover: (item, field) => {
          return <Fragment>
            <ButtonCircle className="_size-19" outline={true} iconName='Edit' onClick={() => console.log(item, field)} />
            <ButtonCircle className="_size-19" outline={true} iconName='Delete' onClick={() => console.log(item, field)} />
          </Fragment>
        },
        tooltip: 'Actions',
      },
    ],
    data: [
      { role: 'Admin', privileges: '14/14', created: '02/07/2018', users: 4, id: 1 },
      { role: 'DSP Admin', privileges: '10/14', created: '10/10/2018', users: 5, id: 2 },
      { role: 'Finance', privileges: '5/14', created: '25/10/2018', users: 8, id: 3 },
    ],
    sorting: {
      field: 'role',
      direction: 'desc',
    },
  };

  table2 = {
    header: [
      { label: 'Name', sortingKey: 'name', className: '_col-120' },
      { label: 'Role', className: '_role_header' },
      { label: 'Member Since', className: '_col-120' },
      { label: 'Last Active', },
      { sorting: false, },
      { label: 'Name2', sortingKey: 'name', },
      { label: 'Date2', sortingKey: 'name', className: '_col-120' },
      { label: 'Name3', sortingKey: 'name', },
      { label: 'Date3', sortingKey: 'name', className: '_col-120' },
      { label: 'Name4', sortingKey: 'name', },
      { label: 'Date4', sortingKey: 'name', className: '_col-120' },
    ],
    body: [
      {
        key: d => d.unsorted ? 'Total' : d.name,
      },
      {
        key: d => d.unsorted ? '' : d.role.label,
        hover: (data, field) => {
          return data.unsorted ? '' : (
            <Select
              options={this.roles}
              value={data.role}
              onChange={(v) => {
                const dataArr = this.state.data;
                const index = dataArr.indexOf(data);
                const el = dataArr.splice(index, 1)[0];
                el.role = {
                  ...v,
                };
                dataArr.splice(index, 0, { ...el });
                this.setState({
                  data: dataArr,
                });
              }}
            />
          )
        },
        className: '_role',
      },
      { key: 'memberSince', className: '_col-120' },
      { key: 'lastActive' },
      {
        key: d => d.unsorted ? '' : d.actions,
        className: '_actions',
        hover: (item, field) => {
          return item.unsorted ? '' : (
            <Fragment>
              <ButtonCircle className="_size-19" outline={true} iconName='Edit' onClick={() => console.log(item, field)} />
              <ButtonCircle className="_size-19" outline={true} iconName='Delete' onClick={() => console.log(item, field)} />
            </Fragment>
          )
        },
      },
      { key: 'name2' },
      { key: 'date2', className: '_col-120' },
      { key: 'name3' },
      { key: 'date3', className: '_col-120' },
      { key: 'name4' },
      { key: 'date4', className: '_col-120' },
    ],
    sorting: {
      field: 'name',
      direction: 'asc',
    },
    rowClickHandler: (e, data, index) => {
      console.log('row clicked', e, data, index);
    },
  };

  handleDragEnd = (item, { start, end }) => {
    this.table.header = [
      ...moveItem(this.table.header, start, end).map(d => ({ ...d })),
    ];
    this.table.body = [
      ...moveItem(this.table.body, start, end).map(d => ({ ...d })),
    ];
    this.forceUpdate();
  };

  render() {
    return (
      <PagePartial
        title="Table"
        className="page-table"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-12">
            <h5 className="title-form-subtitle">Simple table</h5>
          </div>
          <div className="col-12 mt-5">
            <Table
              title={`Roles (${this.table.data.length})`}
              header={this.table.header}
              body={this.table.body}
              data={this.state.firstTableData}
              sorting={this.table.sorting}
              rowKeyExtractor={(data) => data.id}
              draggable={true}
              onDragEnd={this.handleDragEnd}
            />
          </div>
          <div className="col-12 mt-5">
            <h4 className="title-form-subtitle">Frontend table with paging</h4>
          </div>
          <div className="col-12 mt-5">
            <div className="d-flex justify-content-end">
              <Button className="btn-square _shamrock justify-content-end" onClick={() => {
                const newData = generateUsersTable(10);
                const data = this.state.data;
                this.setState({
                  data: [...data.concat(newData)],
                });
              }}>
                Add 10 rows
              </Button>
            </div>
            <Table
              header={this.table2.header}
              title={`Users (${this.state.data.length})`}
              body={this.table2.body}
              data={this.state.data}
              paging={{
                ...this.state.paging,
                totalItemsCount: this.state.data.length,
              }}
              onChange={({ paging }) => {
                this.setState({
                  paging,
                })
              }}
              className="table__users"
              offsetTop={64}
              rowKeyExtractor={(data) => data.id}
              rowClickHandler={this.table2.rowClickHandler}
              freezeColumns={1}
              unsortedData={[
                {
                  unsorted: true,
                  name: 'test',
                  role: { label: 'testy' },
                  memberSince: 'test',
                  lastActive: 'test',
                  actions: 'test',
                  name2: 'test',
                  date2: 'test',
                  name3: 'test',
                  date3: 'test',
                  name4: 'test',
                  date4: 'test',
                }
              ]}
              tbodyRowHeight={45}
            />
          </div>
          <div className="col-12 mt-5">
            <h4 className="title-form-subtitle">Server table with paging</h4>
          </div>
          <div className="col-12 mt-5">
            <ServerTableEmulation />
          </div>
          <div className="col-12 mt-5">
            <h4 className="title-form-subtitle">Infinite scrolling</h4>
          </div>
          <div className="col-8 mt-5">
            <InfiniteScrollTableEmulation />
          </div>
        </div>
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Table with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
              import { Table, Select, ButtonCircle } from 'factor';
              import 'factor/src/scss/_table.scss';

              class YourPage extends React.Component {
                constructor(props) {
                  super(props);

                  this.state = {
                    paging: {
                      activePage: 1,
                      itemsCountPerPage: 10,
                    },
                  };
                }

                table = {
                  header: [
                    { label: 'Name', sortingKey: 'name' },
                    { label: 'Role', className: '_role_header' },
                    { label: 'Member Since' },
                    { label: 'Last Active' },
                    { sorting: false },
                  ],
                  body: [
                    { key: 'name' },
                    {
                      key: (d) => {
                        return d.role.label;
                      },
                      hover: (data, field) => {
                        return <Select
                          options={this.roles}
                          value={data.role}
                          onChange={(v) => {
                            data.role = v;
                            this.setState({});
                          }}
                        />
                      },
                      className: '_role',
                    },
                    { key: 'date' },
                    { key: 'lastActive' },
                    {
                      className: '_actions',
                      hover: (item, field) => {
                        return <div>
                          <ButtonCircle className="_size-19" outline={true} iconName='Edit' onClick={() => console.log(item, field)}/>
                          <ButtonCircle className="_size-19" outline={true} iconName='Delete' onClick={() => console.log(item, field)}/>
                        </div>
                      },
                    },
                  ],
                  data: [...data],
                  sorting: {
                    field: 'name',
                    direction: 'asc',
                  },
                };

                render() {
                  return (
                    <Table
                      header={this.table.header}
                      title="Users (\${this.table.data.length})"
                      body={this.table.body}
                      data={this.table.data}
                      paging={{
                        ...this.state.paging,
                        totalItemsCount: this.table.data.length,
                      }}
                      offsetTop={64}
                      rowKeyExtractor={(data) => data.id}
                    />
                  );
                }
              }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="required">header</td>
                <td>array of objects <br />
                  {'{'} <br />
                  label: string, <br />
                  className: string <br />
                  sortingKey: string <br />
                  compareFunc: func <br />
                  tooltip: string <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Table header elements</td>
              </tr>
              <tr>
                <td className="required">body</td>
                <td>array of objects <br />
                  {'{'} <br />
                  key: string | func: return component <br />
                  hover: func: return component <br />
                  className: string <br />
                  classNameHover: string <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Table body</td>
              </tr>
              <tr>
                <td className="required">data</td>
                <td>array of objects <br />
                  The object fields must be equal with header keys
                </td>
                <td>none</td>
                <td>Table data</td>
              </tr>
              <tr>
                <td className="required">rowKeyExtractor</td>
                <td>function(data, rowIndex): id</td>
                <td>none</td>
                <td>Function for extracting row key</td>
              </tr>
              <tr>
                <td>rowClickHandler</td>
                <td>function(event, data, rowIndex)</td>
                <td>none</td>
                <td>Callback for row click</td>
              </tr>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Additional class</td>
              </tr>
              <tr>
                <td>title</td>
                <td>string</td>
                <td>none</td>
                <td>Table title</td>
              </tr>
              <tr>
                <td>unsortedData</td>
                <td>array of objects <br />
                  The object fields must be equal with header keys
                </td>
                <td>none</td>
                <td>Table data without sort</td>
              </tr>
              <tr>
                <td>paging</td>
                <td>object <br />
                  {'{'} <br />
                  <span className="required">activePage: number</span> <br />
                  <span className="required">totalItemsCount: number</span> <br />
                  <span className="required">itemsCountPerPage: 10 | 20 | 50 | 100</span> <br />
                  pageRangeDisplayed: number <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Table paging <br />
                  <b>Attention:</b> if you use paging you need update it manually with onChange
                </td>
              </tr>
              <tr>
                <td>server</td>
                <td>boolean</td>
                <td>false</td>
                <td>Is table use server data (sorting implemented on server)</td>
              </tr>
              <tr>
                <td>loading (for server)</td>
                <td>boolean</td>
                <td>false</td>
                <td>Used while wait server response</td>
              </tr>
              <tr>
                <td>onChange (for server)</td>
                <td>function({'{'}sorting, paging{'}'})</td>
                <td>none</td>
                <td>Call whenever changes happened</td>
              </tr>
              <tr>
                <td>fixedHeader</td>
                <td>boolean</td>
                <td>true</td>
                <td>Freeze header on scroll</td>
              </tr>
              <tr>
                <td>freezeRows</td>
                <td>number</td>
                <td>none</td>
                <td>Freeze number of rows. Starts from 0</td>
              </tr>
              <tr>
                <td>freezeColumns</td>
                <td>number</td>
                <td>none</td>
                <td>Freeze number of columns. Starts from 0</td>
              </tr>
              <tr>
                <td>enableContextMenu</td>
                <td>boolean</td>
                <td>true</td>
                <td>Enable/Disable context menu</td>
              </tr>
              <tr>
                <td>offsetTop</td>
                <td>number</td>
                <td>0</td>
                <td>Offset for the freezed table header. For example if you have fixed navigation bar
                with height 64px, the offsetTop will be 64.
                </td>
              </tr>
              <tr>
                <td>sorting</td>
                <td>object <br />
                  {'{'} <br />
                  field: string <br />
                  direction: 'asc' | 'desc' <br />
                  compareFunc: func(direction) return -1 | 0 | 1 <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Table sorting data</td>
              </tr>
              <tr>
                <td>infiniteScroll</td>
                <td>boolean</td>
                <td>false</td>
                <td>Add infinite scroll for loading data</td>
              </tr>
              <tr>
                <td>InfiniteScrollComponent</td>
                <td>object <br />
                  {'{'} <br />
                  loadMore: func(pageNumber) {} <br />
                  {'}'}
                </td>
                <td>none</td>
                <td>Additional params from <a href={'https://github.com/CassetteRocks/react-infinite-scroller#props'}
                  rel="noopener noreferrer" target="_blank">here</a></td>
              </tr>
              <tr>
                <td>draggable</td>
                <td>boolean</td>
                <td>false</td>
                <td>Draggable columns</td>
              </tr>
              <tr>
                <td>onDragEnd</td>
                <td>function(item, {'{'}start: number, end: number{'}'})</td>
                <td>none</td>
                <td>Callback after finish drag</td>
              </tr>
              <tr>
                <td>freezeUpdateHandler</td>
                <td>function({'{'}rows: number, columns: number{'}'})</td>
                <td>none</td>
                <td>Callback after changing numbers of freezing columns or rows</td>
              </tr>
              <tr>
                <td>theadRowHeight</td>
                <td>number</td>
                <td>32</td>
                <td>Sets table head row height in pixels</td>
              </tr>
              <tr>
                <td>tbodyRowHeight</td>
                <td>number</td>
                <td>32</td>
                <td>Sets table body row height in pixels</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
