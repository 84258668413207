import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { ButtonCircle, Checkbox, NavigationBar, Select } from 'factor';
import './navigationPage/style.scss';
import logoImage from './navigationPage/ooni-logo.png';
import { ReactComponent as MarketLogo } from './navigationPage/app-13.svg';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class NavigationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onTop: false,
      onTop2: false,
      onTop3: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  selectOptions = [{
    label: 'Recently Updated',
    value: 1,
  }, {
    label: 'Created',
    value: 2,
  }];

  render() {
    const {
      onTop,
      onTop2,
      onTop3,
    } = this.state;

    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Navigation"
        className={
          `page-navigation ${onTop || onTop2 || onTop3 ? 'page-navigation__on-top' : ''}`
        }
      >
        <h5 id="example">Example</h5>
        <div className="mt-3">
          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Checkbox checked={onTop} onChange={(v) => this.setState({
                onTop: v,
              })} label="Show on top" />
            </div>
            <div className="col-12 mt-2">
              <h5 className="title-card">Default Navbar</h5>
              <NavigationBar className={onTop ? '' : '_relative'} title="Report Builder" isBeta />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Checkbox checked={onTop2} onChange={(v) => this.setState({
                onTop2: v,
              })} label="Show on top" />
            </div>
            <div className="col-12 mt-2">
              <h5 className="title-card">Custom title and actions</h5>
              <NavigationBar
                className={onTop2 ? '' : '_relative'}
                title="Report Builder"
                backButton={false}
                popupContent={
                  <div>
                    Some content here
                  </div>
                }
              >
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <div className="d-flex">
                    <ButtonCircle key="pdf" outline={true} iconName="XLS" />
                    <ButtonCircle key="csv" outline={true} iconName="CSV" />
                    <ButtonCircle key="copy" outline={true} iconName="Copy" />
                  </div>
                  <div className="d-flex align-items-center">
                    <ButtonCircle key="apps" outline={true} iconName="Apps" />
                  </div>
                </div>
              </NavigationBar>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 mt-2">
              <Checkbox checked={onTop3} onChange={(v) => this.setState({
                onTop3: v,
              })} label="Show on top" />
            </div>
            <div className="col-12 mt-2">
              <h5 className="title-card">Custom Navbar</h5>
              <NavigationBar
                className={onTop3 ? '' : '_relative'}
                title="Marketplace"
                profileLogo={
                  <img className="pr-2 ooni__logo" alt="Logo" src={logoImage} />
                }
                backHandler={() => window.alert('Click')}
                leftLogo={<div className="market-logo"><MarketLogo /></div>}
                popupContent={
                  <div>
                    Some content here
                  </div>
                }
              >
                <div key="select" className="nav-bar__select ml-2">
                  <Select options={this.selectOptions} value={1} />
                </div>
              </NavigationBar>
            </div>
          </div>
        </div>
        <br />
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Navigation bar with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { NavigationBar } from 'factor';
                import 'factor/src/scss/_navbar.scss';

                class YourPage extends React.Component {
                  render() {
                    return (
                      <NavigationBar />
                      <NavigationBar
                        title="Report Builder"
                        popupContent={
                          <div>
                            Some content here
                          </div>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center flex-grow-1">
                          <div className="d-flex">
                            <ButtonCircle key="pdf" outline={true} iconName="XLS"/>
                            <ButtonCircle key="csv" outline={true} iconName="CSV"/>
                            <ButtonCircle key="copy" outline={true} iconName="Copy"/>
                          </div>
                          <div className="d-flex align-items-center">
                            <ButtonCircle key="apps" outline={true} iconName="Apps"/>
                          </div>
                        </div>
                      </NavigationBar>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Additional class</td>
              </tr>
              <tr>
                <td>backButton</td>
                <td>boolean</td>
                <td>true</td>
                <td>Show/hide back button</td>
              </tr>
              <tr>
                <td>backHandler</td>
                <td>function</td>
                <td>none</td>
                <td>Back button callback</td>
              </tr>
              <tr>
                <td>title</td>
                <td>string</td>
                <td>none</td>
                <td>Navigation title</td>
              </tr>
              <tr>
                <td>leftLogo</td>
                <td>element</td>
                <td>Iqm logo</td>
                <td>Left logo</td>
              </tr>
              <tr>
                <td>profileLogo</td>
                <td>element</td>
                <td>Iqm static logo</td>
                <td>Profile logo</td>
              </tr>
              <tr>
                <td>profileAvatar</td>
                <td>element</td>
                <td>Colored circle</td>
                <td>Avatar</td>
              </tr>
              <tr>
                <td>popupContent</td>
                <td>element</td>
                <td>none</td>
                <td>The profile popup content</td>
              </tr>
              <tr>
                <td>isBeta</td>
                <td>boolean</td>
                <td>false</td>
                <td>Shows 'beta' sign under title</td>
              </tr>
              <tr>
                <td>children</td>
                <td>elements</td>
                <td>none</td>
                <td>Elements after title</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
