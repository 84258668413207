const firstNames = [
  'Gary',
  'Carolyn',
  'Chase',
  'Ian',
  'Sam',
  'Dominic',
  'Clifford',
  'Dominic',
];

const lastNames = [
  'Cross',
  'Vilkins',
  'Colon',
  'Mitchel',
  'Lyndsey',
  'Scott',
  'Higgins',
  'Brady',
];

export const roles = [
  'Admin',
  'DSP Admin',
  'Finance',
];

const dates = [
  '02/27/2018',
  '10/10/2018',
  '07/28/2018',
  '06/30/2018',
  '01/12/2018',
  '03/15/2018',
  '04/02/2018',
  '07/02/2018',
];

const lastActiveDates = [
  '2 days ago',
  '3 days ago',
  '5 days ago',
  '1 week ago',
  '2 week ago',
  '3 week ago',
  '1 month ago',
  '2 months ago',
];

const getRandomValue = (to = 2) => {
  return Math.floor(Math.random() * to);
};

let id = 0;
function getId() {
  id += 1;
  return id;
}
export const generateUsersTable = (length) => {
  const data = [];

  for (let ii = 0; ii < length; ii++) {
    const name = firstNames[getRandomValue(firstNames.length - 1)];
    const lName = lastNames[getRandomValue(lastNames.length - 1)];
    const obj = {
      id: getId(),
      name: `${name} ${lName}`,
      role: [roles[getRandomValue(roles.length -1)]].map(v => ({label:v, value:v}))[0],
      date: dates[getRandomValue(dates.length - 1)],
      lastActive: lastActiveDates[getRandomValue(lastActiveDates.length - 1)],
      name2: `${name} ${lName}`,
      date2: dates[getRandomValue(dates.length - 1)],
      name3: `${name} ${lName}`,
      date3: dates[getRandomValue(dates.length - 1)],
      name4: `${name} ${lName}`,
      date4: dates[getRandomValue(dates.length - 1)],
    };
    data.push(obj);
  }

  return data;
};