import React from 'react';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartial } from '../demoComponents/PagePartial';

export const HomePage = () => (
  <PagePartial
    title="Getting started"
  >
    <h4>Installing</h4>
    <p>
      While it is a private repository you need add to <code className="inline red">package.json</code> dependencies
      this link manually.
    </p>
    <p>Add to dependencies</p>
    <Highlight className="language-json" code="js">
      {
        `
            {
                "factor": "git+https://<user_name>:<user_password>@bitbucket.org/inappad/dsp-react-ui-components.git"
            }
          `
      }
    </Highlight>
    <p>
      where <b>user_name</b> and <b>user_password</b> - user name and password for login to bitbicket. <br/>
      <b>version</b> - tag for downloading (you can find tag in repository).
    </p>
    <p>Or with <b>SSH</b></p>
    <Highlight className="language-json" code="js">
      {
        `
            {
                "factor": "git+ssh://git@bitbucket.org/inappad/dsp-react-ui-components.git"
            }
          `
      }
    </Highlight>
    <br/>
    <h4>Available Scripts</h4>
    <p>In the project directory, you can run:</p>
    <code className="snippet">npm run start</code>
    <p>Runs the app in the development mode and watch <b>components</b> changes</p>
    <code className="snippet"> npm start-demo</code>
    <p>Runs the demo app. Open <b>http://localhost:3000</b> to view it in the browser. <br/>
      The page will reload if you make edits. You will also see any lint errors in the console.
    </p>
    <code className="snippet">npm run build-demo</code>
    <p>
      Builds the app for production to the `build` folder.<br/>
      It correctly bundles React in production mode and optimizes the build for the best performance.

      The build is minified and the filenames include the hashes.<br/>
      Your app is ready to be deployed!
    </p>
    <code className="snippet">npm run build</code>
    <p>Builds imported components to <b>lib</b> folder</p>
  </PagePartial>
);
