import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.css'
import './App.scss'
import store from './store/index';
import App from './App'

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
