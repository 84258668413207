import React, { Component } from 'react';
import { ParamsCard, ActionMenu, ParamsCardsList, ParamsCardsDate } from 'factor';

import styles from './paramsCardPage/paramsCardPage.module.scss';
import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

const PARAMS_LIST = [
  { label: 'Impressions:', value: '910,921', },
  { label: 'Clicks:', value: '1,875', },
  { label: 'CTR:', value: '0.21%', },
  { label: 'Spent:', value: '$17,692.79', },
];

const ACTIONS = [
  { label: 'Edit', iconName: 'Edit' },
  { label: 'Duplicate', iconName: 'Copy' },
  { label: 'Subscribe', iconName: 'AddEmail' },
  { label: 'Delete', iconName: 'Delete' },
];

const PARAMS_CARDS_LIST = [
  {
    date: '02/26/2019',
    list: [
      {
        title: 'Campaign',
        list: PARAMS_LIST,
        menu: <ActionMenu actions={ACTIONS} />
      },
      { title: 'Test', list: PARAMS_LIST },
      { title: 'Campaign Groups', list: PARAMS_LIST },
    ],
  },
  {
    date: '02/27/2019',
    list: [
      { title: 'Creatives', list: PARAMS_LIST },
      { title: 'Creatives', list: PARAMS_LIST },
      { title: 'Creatives', list: PARAMS_LIST },
    ],
  },
  {
    date: '03/04/2019',
    list: [
      { title: 'Ages', list: PARAMS_LIST },
      { title: 'Campaign', list: PARAMS_LIST },
      { title: 'Campaign', list: PARAMS_LIST },
    ],
  },
];

export class ParamsCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardActive: false,
      searchValue: '',
      isSearchOpen: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  actions = ACTIONS.map(action => ({
    ...action,
    onClick: () => console.log(action.label)
  }));

  setCardActivity = () => {
    const { isCardActive } = this.state;

    this.setState({ isCardActive: !isCardActive });
  };

  onSearchChange = (value) => this.setState({ searchValue: value });

  toggleSearch = () => {
    const { isSearchOpen } = this.state;
    this.setState({ isSearchOpen: !isSearchOpen });
  };

  render() {
    const {
      isCardActive,
      searchValue,
      isSearchOpen,
    } = this.state;
    return (
      <PagePartial
        title="ParamsCard"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">ParamsCard</h4>
            <ParamsCard
              title="Campaign"
              list={PARAMS_LIST}
              actions={this.actions}
              isActive={isCardActive}
              onClick={this.setCardActivity}
              counts={{
                whitelist: 4,
                blacklist: 10,
              }}
            />
          </div>
        </div>
        <div className="row example">
          <div className="col-12">
            <h4 className="title-card mb-2">ParamsCardsDate & ParamsCardsList</h4>
            <ParamsCardsList
              className={styles.sticky}
              searchValue={searchValue}
              onSearchChange={this.onSearchChange}
              toggleSearch={this.toggleSearch}
              isSearchOpen={isSearchOpen}
            >
              {PARAMS_CARDS_LIST.map(batch => (
                <div key={batch.date}>
                  <ParamsCardsDate date={batch.date} />
                  {batch.list.map((item, k) => (
                    <ParamsCard
                      key={k}
                      title={item.title}
                      list={item.list}
                    />
                  ))}
                </div>
              ))}
            </ParamsCardsList>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React, { Component } from 'react';
                import { ParamsCard, ActionMenu, ParamsCardsList, ParamsCardsDate } from 'factor';

                const PARAMS_LIST = [
                  { label: 'Impressions:', value: '910,921', },
                  { label: 'Clicks:', value: '1,875', },
                  { label: 'CTR:', value: '0.21%', },
                  { label: 'Spent:', value: '$17,692.79', },
                ];

                const ACTIONS = [
                  { label: 'Edit', iconName: 'Edit' },
                  { label: 'Duplicate', iconName: 'Copy' },
                  { label: 'Subscribe', iconName: 'AddEmail' },
                  { label: 'Delete', iconName: 'Delete' },
                ];

                const PARAMS_CARDS_LIST = [
                  {
                    date: '02/26/2019',
                    list: [
                      {
                        title: 'Campaign',
                        list: PARAMS_LIST,
                        menu: <ActionMenu actions={ACTIONS} />
                      },
                      { title: 'Test', list: PARAMS_LIST },
                      { title: 'Campaign Groups', list: PARAMS_LIST },
                    ],
                  },
                  {
                    date: '02/27/2019',
                    list: [
                      { title: 'Creatives', list: PARAMS_LIST },
                      { title: 'Creatives', list: PARAMS_LIST },
                      { title: 'Creatives', list: PARAMS_LIST },
                    ],
                  },
                  {
                    date: '03/04/2019',
                    list: [
                      { title: 'Ages', list: PARAMS_LIST },
                      { title: 'Campaign', list: PARAMS_LIST },
                      { title: 'Campaign', list: PARAMS_LIST },
                    ],
                  },
                ];

                export class ParamsCardPage extends Component {
                  constructor(props) {
                    super(props);
                    this.state = {
                      searchValue: '',
                      isSearchOpen: false,
                    };
                  }

                  actions = ACTIONS.map(action => ({
                    ...action,
                    onClick: () => console.log(action.label)
                  }));

                  onSearchChange = (value) => this.setState({ searchValue: value });

                  toggleSearch = () => {
                    const { isSearchOpen } = this.state;
                    this.setState({ isSearchOpen: !isSearchOpen });
                  };

                  render() {
                    const {
                      searchValue,
                      isSearchOpen,
                    } = this.state;
                    return (
                      <ParamsCardsList
                        className={styles.sticky}
                        searchValue={searchValue}
                        onSearchChange={this.onSearchChange}
                        toggleSearch={this.toggleSearch}
                        isSearchOpen={isSearchOpen}
                      >
                        {PARAMS_CARDS_LIST.map(batch => (
                          <div key={batch.date}>
                            <ParamsCardsDate date={batch.date} />
                            {batch.list.map((item, k) => (
                              <ParamsCard
                                title={item.title}
                                list={item.list}
                                actions={this.actions}
                              />
                            ))}
                          </div>
                        ))}
                      </ParamsCardsList>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">ParamsCard Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Custom css class</td>
              </tr>
              <tr>
                <td>title</td>
                <td>string</td>
                <td>none</td>
                <td>Title</td>
              </tr>
              <tr>
                <td>list</td>
                <td>{`{ label: string; value: string; }`}</td>
                <td>none</td>
                <td>List of parameters to show in card</td>
              </tr>
              <tr>
                <td>isActive</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>If card is selected</td>
              </tr>
              <tr>
                <td>onClick</td>
                <td>function</td>
                <td>none</td>
                <td>Card onClick</td>
              </tr>
              <tr>
                <td>counts</td>
                <td>{`{ whitelist: number; blacklist: number; }`}</td>
                <td>undefined</td>
                <td>Counts inside squares in the header</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">ParamsCardsList Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Custom css class</td>
              </tr>
              <tr>
                <td>searchValue</td>
                <td>string</td>
                <td>undefined</td>
                <td>Search TextField value</td>
              </tr>
              <tr>
                <td>onSearchChange</td>
                <td>function</td>
                <td>(value: string) => void</td>
                <td>Search TextField onChange</td>
              </tr>
              <tr>
                <td>isSearchOpen</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>Search TextField open/closed state</td>
              </tr>
              <tr>
                <td>toggleSearch</td>
                <td>function</td>
                <td>undefined</td>
                <td>Open/close search TextField</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">ParamsCardsDate Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Custom css class</td>
              </tr>
              <tr>
                <td>date</td>
                <td>string</td>
                <td>undefined</td>
                <td>Date</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
