import React, { Component } from 'react';
import './buttonsPage/buttons.scss';
import { Highlight } from '../demoComponents/Highlight';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Button, Checkbox, ButtonCircle, ButtonsGroup, Icon, commonIcons } from 'factor';
import { PagePartialHeader } from '../demoComponents/PagePartialHeader';
import { ButtonMarkupRow } from './buttonsPage/ButtonMarkupRow';

export class ButtonsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      btnSize: null,
    };
  }

  pageNavigation = [
    { title: 'Example cornflower-blue', link: '#cornflower-blue' },
    { title: 'Example persimmon', link: '#persimmon' },
    { title: 'Example gray', link: '#gray' },
    { title: 'Example with icons', link: '#exampleWithIcons' },
    { title: 'Example circle', link: '#example4' },
    { title: 'React usage', link: '#react' },
    { title: 'Html usage', link: '#html' },
  ];

  renderToggle() {
    return (
      <div className="row mb-2 mt-2 align-items-center">
        <div className="col-6">
          <Checkbox
            label="Disable / Enable buttons"
            checked={this.state.disabled}
            onChange={(v) => this.setState({ disabled: v })}
          />
        </div>
        <div className="col-6 d-flex justify-content-end">
          {this.renderSizeToggle()}
        </div>
      </div>
    );
  }

  sizes = (function() {
    const sizes = [
      { title: 'Default', value: null },
      { title: 'Medium', value: 'md' },
      { title: 'Large', value: 'lg' },
    ];
    return sizes;
  })();

  renderSizeToggle() {
    return (
      <ButtonsGroup
        value={this.state.btnSize}
        items={this.sizes}
        onChange={(v) => this.setState({
          btnSize: v,
        })}
      />
    );
  }

  render() {
    const { disabled, btnSize } = this.state;

    const cornflowerBlue = <Button size={btnSize} className="btn-square _cornflower-blue" disabled={disabled}>Button</Button>;
    const cornflowerBlueFilled = <Button size={btnSize} className="btn-square _cornflower-blue _filled" disabled={disabled}>Button</Button>;
    const roundedCornflowerBlue = <Button size={btnSize} className="btn-round _cornflower-blue" disabled={disabled}>Button</Button>;
    const roundedCornflowerBlueFilled = <Button size={btnSize} className="btn-round _cornflower-blue _filled" disabled={disabled}>Button</Button>;
    const roundedCornflowerBluePrefix = (<Button size={btnSize} className="btn-round _cornflower-blue" disabled={disabled}>
      <span className="btn-round__prefix">
        <Icon name="Plus" />
      </span>
        Button
    </Button>);
    const roundedCornflowerBlueFilledPrefix = (<Button size={btnSize} className="btn-round _cornflower-blue _filled" disabled={disabled}>
      <span className="btn-round__prefix">
        <Icon name="Plus" />
      </span>
        Button
    </Button>);

    const persimmon = <Button size={btnSize} disabled={disabled}>Button</Button>;
    const persimmonFilled = <Button size={btnSize} className="btn-square _persimmon _filled" disabled={disabled}>Button</Button>;
    const roundedPomegranate = <Button size={btnSize} className="btn-round _persimmon" disabled={disabled}>Button</Button>;
    const roundedPomegranateFilled = <Button size={btnSize} className="btn-round _persimmon _filled" disabled={disabled}>Button</Button>;
    const roundedPomegranatePrefix = (<Button size={btnSize} className="btn-round _persimmon _filled _ripple-hover" disabled={disabled}>
      <span className="btn-round__prefix">
        <Icon name="Plus" />
      </span>
      Button
    </Button>);

    const gray = <Button size={btnSize} className="btn-square _gray" disabled={disabled}>Button</Button>;
    const grayFilled = <Button size={btnSize} className="btn-square _gray _filled" disabled={disabled}>Button</Button>;
    const roundedGray = <Button size={btnSize} className="btn-round _gray" disabled={disabled}>Button</Button>;
    const roundedGrayFilled = <Button size={btnSize} className="btn-round _gray _filled" disabled={disabled}>Button</Button>;
    const roundedFountainButtonPrefix = (<Button size={btnSize} className="btn-round _gray _filled" disabled={disabled}>
      <span className="btn-round__prefix">
        <Icon name="Plus" />
      </span>
      Button
    </Button>);

    return (
      <article className="page buttons-page">
        <PagePartialHeader
          title="Buttons"
          navigation={this.pageNavigation}
        />
        <div className="page__content">
          <h5 id="cornflower-blue">Example cornflower-blue</h5>
          {this.renderToggle()}
          <div className="example row">
            <div className="col buttons__row mb-2">{cornflowerBlue}</div>
            <div className="col buttons__row mb-2">{cornflowerBlueFilled}</div>
            <div className="col buttons__row mb-2">{roundedCornflowerBlue}</div>
            <div className="col buttons__row mb-2">{roundedCornflowerBlueFilled}</div>
            <div className="col buttons__row mb-2">{roundedCornflowerBluePrefix}</div>
            <div className="col buttons__row mb-2">{roundedCornflowerBlueFilledPrefix}</div>
          </div>
          <ButtonMarkupRow component={cornflowerBlue} text="Default cornflower-blue button"/>
          <ButtonMarkupRow component={cornflowerBlueFilled} text="cornflower-blue filled button"/>
          <ButtonMarkupRow component={roundedCornflowerBlue} text="Default cornflower-blue rounded button"/>
          <ButtonMarkupRow component={roundedCornflowerBlueFilled} text="Rounded cornflower-blue filled button"/>
          <ButtonMarkupRow component={roundedFountainButtonPrefix} text="Rounded cornflower-blue button with icon"/>
          <br />

          <h5 id="persimmon">Example Persimmon</h5>
          {this.renderToggle()}
          <div className="example row">
            <div className="col buttons__row mb-2">{persimmon}</div>
            <div className="col buttons__row mb-2">{persimmonFilled}</div>
            <div className="col buttons__row mb-2">{roundedPomegranate}</div>
            <div className="col buttons__row mb-2">{roundedPomegranateFilled}</div>
            <div className="col buttons__row mb-2">{roundedPomegranatePrefix}</div>
          </div>
          <ButtonMarkupRow component={persimmon} text="Default persimmon button"/>
          <ButtonMarkupRow component={persimmonFilled} text="Persimmon filled button"/>
          <ButtonMarkupRow component={roundedPomegranate} text="Default persimmon rounded button"/>
          <ButtonMarkupRow component={roundedPomegranateFilled} text="Rounded persimmon filled button"/>
          <ButtonMarkupRow component={roundedPomegranatePrefix} text="Rounded persimmon button with icon"/>
          <br />

          <h5 id="gray">Example Gray</h5>
          {this.renderToggle()}
          <div className="example row">
            <div className="col buttons__row mb-2">{gray}</div>
            <div className="col buttons__row mb-2">{grayFilled}</div>
            <div className="col buttons__row mb-2">{roundedGray}</div>
            <div className="col buttons__row mb-2">{roundedGrayFilled}</div>
            <div className="col buttons__row mb-2">{roundedFountainButtonPrefix}</div>
          </div>
          <ButtonMarkupRow component={gray} text="Default gray button"/>
          <ButtonMarkupRow component={grayFilled} text="Gray filled button"/>
          <ButtonMarkupRow component={roundedGray} text="Default gray rounded button"/>
          <ButtonMarkupRow component={roundedGrayFilled} text="Rounded gray filled button"/>
          <ButtonMarkupRow component={roundedFountainButtonPrefix} text="Rounded gray button with icon"/>
          <br />

          <h5 id="exampleWithIcons">Buttons with Common Icons</h5>
          <div className="row example">
            {Object.keys(commonIcons).map(name => {
              return (
                <div className="col-2 mb-3 d-flex justify-content-center flex-column align-items-center" key={name}>
                  <Button size={btnSize} className="btn-round _cornflower-blue">
                    <span className="btn-round__prefix">
                      <Icon name={name} />
                    </span>
                    Button
                  </Button>
                </div>
              )
            })}
          </div>
          <div className="row example buttons-page__icon-buttons">
            {Object.keys(commonIcons).map(name => {
              return (
                <div className="col-2 mb-3 d-flex justify-content-center flex-column align-items-center" key={name}>
                  <Button size={btnSize} className="btn-round _cornflower-blue _filled">
                    <span className="btn-round__prefix">
                      <Icon name={name} />
                    </span>
                    Button
                  </Button>
                </div>
              )
            })}
          </div>

          <h5 id="example4">Example Circle with Icon</h5>
          <div className="example">
            <div className="row mb-2">
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="Copy"/></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="XLS"/></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="CSV"/></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="Apps"/></div>
            </div>
            <div className="row mb-2">
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="Copy" className="_selected" /></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="XLS" className="_selected" /></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="CSV" className="_selected" /></div>
              <div className="col-3 d-flex justify-content-center"><ButtonCircle iconName="Apps" className="_selected" /></div>
            </div>
          </div>
          <PagePartialUsageBlock
            id="react"
            subTitle="(react only)"
          >
            <p>For using this buttons with React use this</p>
            <Highlight className="language-jsx" code="js">
              {
                `
                  import { Button } from 'factor';
                  import 'factor/src/scss/_btn.scss';

                  class YourPage extends React.Component {
                    render() {
                      return (
                        <Button />
                      );
                    }
                  }
                  `
              }
            </Highlight>
            <p>For using this Circle buttons with React use this</p>
            <Highlight className="language-jsx" code="js">
              {
                `
                  import { ButtonCircle } from 'factor';
                  import 'factor/src/scss/_btn.scss';

                  class YourPage extends React.Component {
                    render() {
                      return (
                        <ButtonCircle iconName="Copy" outline={true}/>
                      );
                    }
                  }
                  `
              }
            </Highlight>
          </PagePartialUsageBlock>
          <PagePartialUsageBlock
            title={<h6 className="header-block__title">Params</h6>}
          >
            <table className="props-table">
              <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>btn-square _persimmon</td>
                <td>Button class name</td>
              </tr>
              <tr>
                <td>size</td>
                <td>enum: xs | sm</td>
                <td>none</td>
                <td>Button size</td>
              </tr>
              <tr>
                <td>ripple</td>
                <td>boolean</td>
                <td>true</td>
                <td>Add the ripple effect</td>
              </tr>
              <tr>
                <td>iconName (for circle button)</td>
                <td>string</td>
                <td>none</td>
                <td>Icon name</td>
              </tr>
              <tr>
                <td>iconClass (for circle button)</td>
                <td>string</td>
                <td>none</td>
                <td>Icon class name</td>
              </tr>
              <tr>
                <td>outline (for circle button)</td>
                <td>boolean</td>
                <td>false</td>
                <td>Outline button</td>
              </tr>
              <tr>
                <td>inverse (for circle button)</td>
                <td>boolean</td>
                <td>false</td>
                <td>Inverse colors</td>
              </tr>
              <tr>
                <td>...rest</td>
                <td>any</td>
                <td>none</td>
                <td>All button properties</td>
              </tr>
              </tbody>
            </table>
          </PagePartialUsageBlock>
          <br/>
          <PagePartialUsageBlock
            subTitle="(html only)"
            id="html"
          >
            <p>For using this buttons you need to import <code className="inline red">btn.scss</code> in your scss file.
            </p>
            <Highlight className="language-css">
              {
                `@import "~factor/src/scss/btn";`
              }
            </Highlight>
            <p>After that you can use html above.</p>
          </PagePartialUsageBlock>
        </div>
      </article>
    );
  }
}
