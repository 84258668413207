import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ButtonCircle, NavigationBar, Tooltip, CustomerSelect } from 'factor';
import './header/header.scss';
import { ReactComponent as DSLogo } from './header/DesignSystem.svg';

export class Header extends PureComponent {
  constructor() {
    super();
    this.state = {
      value: null,
    };
  }

  options = [
    {
      id: '80',
      label: 'FName 180 LName 180',
      email: 'email_180@example.com',
      image: 'apple-touch-icon.png',
      value: 'FName 180 LName 180',
    },
    {
      id: '81',
      label: 'FName 181 LName 181',
      email: 'email_181@example.com',
      image: 'apple-touch-icon.png',
      value: 'FName 181 LName 181',
    },
    {
      id: '82',
      label: 'FName 182 LName 182',
      email: 'email_182@example.com',
      image: 'apple-touch-icon.png',
      value: 'FName 182 LName 182',
    },
  ];

  onChange = (value) => this.setState({value});

  render() {
    const {
      onMenuToggle,
    } = this.props;
    const {
      value,
    } = this.state;

    return (
      <NavigationBar
        title={
          <Link to={'/'}>Design System and Framework</Link>
        }
        leftLogo={<div className="header__logo">
          <Link to={'/'}>
            <DSLogo />
          </Link>
        </div>}
        backButton={
          <Tooltip label="Toggle Menu" position="right" auto={false}>
            <ButtonCircle key="menu" onClick={onMenuToggle} outline={true} iconName="Menu" />
          </Tooltip>
        }
      >
        <CustomerSelect
          className="ml-auto"
          options={this.options}
          value={value}
          onChange={this.onChange}
        />
        <div className="ml-2">
          <ButtonCircle iconName="Apps" outline={true} className="_size-16" />
        </div>
      </NavigationBar>
    );
  }
}
