import React from 'react';
import { ButtonCircle, Snackbar } from 'factor';

const COPY_TITLE = 'Copy';
const COPIED_TITLE = 'Copied!';

export class CopyToClipboardButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copyTitle: COPY_TITLE,
      showSnackbar: false,
    };
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  onCopy = (code) => {
    const input = document.createElement('textarea');
    input.className = 'palette__input';
    input.value = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    this.setState({
      copyTitle: COPIED_TITLE,
      showSnackbar: true,
    });

    setTimeout(() => this.setState({
      copyTitle: COPY_TITLE,
      showSnackbar: false,
    }), 2000);
  };

  render() {
    const {
      code,
      ...rest
    } = this.props;
    const {
      showSnackbar,
    } = this.state;

    return (
      <React.Fragment>
        <ButtonCircle onClick={() => this.onCopy(code)} iconName="Copy" {...rest}/>
        <Snackbar
          open={showSnackbar}
          onClose={this.handleCloseSnackbar}
          message={COPIED_TITLE}
          action={[
            <span key="close" onClick={this.handleCloseSnackbar} className="copy-to-clipboard__snackbar-action">CLOSE</span>
          ]}
        />
      </React.Fragment>
    );
  }
}
