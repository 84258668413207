import React, { Component } from 'react';
import moment from 'moment';
import './datesPickerPage/style.scss';
import { PagePartial } from '../demoComponents/PagePartial';
import { DatesPicker } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class DatesPickerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: new Date(),
      dateRange2: {
        start: new Date(1541192400000),
        end: new Date(1541765208969),
      },
      dateRange3: {
        start: new Date(moment().subtract(10, 'day')),
        end: new Date(),
      },
      dateRange4: {
        start: new Date(1541192400000),
        end: new Date(1541765208969),
        range: '2months',
      },
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  dateRangePreselectedCustom = [{
    title: '2 Weeks',
    key: '2weeks',
    start: moment().add(-2, 'week').add(1, 'day'),
    end: moment(),
  }, {
    title: '2 Months',
    key: '2months',
    start: moment().add(-2, 'month').add(1, 'day'),
    end: moment(),
  }];

  render() {
    const {
      dateRange,
      dateRange2,
      dateRange3,
      dateRange4,
    } = this.state;

    return (
      <PagePartial
        title="Date Picker"
        navigation={this.pageNavigation}
        className="datepicker-page"
      >
        <h5 id="example">Example</h5>
        <div className="example row align-items-end">
          <div className="col-6">
            <DatesPicker
              updateDateRange={(value) => {
                this.setState({
                  dateRange: new Date(value),
                })
              }}
              singleDateMode={true}
              dateFormat="DD/MM/YYYY"
              singleDate={dateRange}
              datePickerProps={{
                numberOfCalendars: 1,
                minimumDate: new Date(),
              }}
              labels={['Start Date:']}
              tooltipParams={{ label: 'DatesPicker Tooltip' }}
            />
          </div>
          <div className="col-6">
            <DatesPicker
              updateDateRange={(start, end) => {
                this.setState({
                  dateRange2: {
                    start: new Date(start),
                    end: new Date(end),
                  },
                })
              }}
              dateFormat="DD/MM/YYYY"
              dateRange={dateRange2}
              datePickerProps={{
                numberOfCalendars: 1,
              }}
            />
          </div>
          <div className="col-6">
            <DatesPicker
              className="_error"
              dateRangePickerClassName="_error"
              updateDateRange={(start, end) => {
                this.setState({
                  dateRange3: {
                    start: start,
                    end: end,
                  },
                })
              }}
              dateRange={dateRange3}
              datePickerProps={{
                numberOfCalendars: 2,
                maximumDate: new Date(),
              }}
              preselected={true}
              insidePreselectors={true}
            />
          </div>
          <div className="col-6 mt-3">
            <DatesPicker
              updateDateRange={(start, end) => this.setState({
                dateRange4: {
                  start: new Date(start),
                  end: new Date(end),
                },
              })}
              dateRange={dateRange4}
              datePickerProps={{
                numberOfCalendars: 2,
                maximumDate: new Date(),
                singleDateRange: true,
              }}
              preselected={this.dateRangePreselectedCustom}
              tooltipParams={{ label: 'DatesPicker Tooltip' }}
            />
          </div>
          <div className="col-6 mt-3">
            <DatesPicker
              updateDateRange={(value) => this.setState({
                dateRange: new Date(value),
              })}
              singleDateMode={true}
              dateFormat="DD/MM/YYYY hh:mm A"
              singleDate={dateRange}
              datePickerProps={{
                numberOfCalendars: 1,
                minimumDate: new Date(),
              }}
              labels={['Start Date:']}
              withTimePicker={true}
            />
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Dates Picker with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                  import { DatesPicker } from 'factor';
                  import 'factor/src/scss/_date-range-picker.scss';

                  class YourPage extends React.Component {
                    constructor(props) {
                      super(props);

                      this.state = {
                        dateRange: {
                          start: new Date(1541192400000),
                          end: new Date(1541765208969),
                        },
                      };
                    }
                    render() {
                      const {
                        dateRange,
                      } = this.state;

                      return (
                        <DatesPicker
                          updateDateRange={(start, end) => this.setState({
                            dateRange: {
                              start: new Date(start),
                              end: new Date(end),
                            }
                          })}
                          dateFormat="DD/MM/YYYY"
                          dateRange={dateRange}
                          datePickerProps={{
                            numberOfCalendars: 1,
                            maximumDate: new Date()
                          }}
                          tooltipParams={{ label: 'DatesPicker Tooltip' }}
                        />
                      );
                    }
                  }
                  `
            }
          </Highlight>
          <p>With custom preselected range</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                  class YourPage extends React.Component {
                    constructor(props) {
                      super(props);

                      this.state = {
                        dateRange: {
                          start: new Date(1541192400000),
                          end: new Date(1541765208969),
                        },
                      };
                    }

                    dateRangePreselectedCustom = [{
                      title: '2 Weeks',
                      key: '2weeks',
                      start: moment().add(-2, 'week').add(1, 'day'),
                      end: moment(),
                    }, {
                      title: '2 Months',
                      key: '2months',
                      start: moment().add(-2, 'month').add(1, 'day'),
                      end: moment(),
                    }];

                    render() {
                      const {
                        dateRange,
                      } = this.state;

                      return (
                        <DatesPicker
                          updateDateRange={(start, end) => this.setState({
                            dateRange: {
                              start: new Date(start),
                              end: new Date(end),
                            }
                          })}
                          dateFormat="DD/MM/YYYY"
                          dateRange={dateRange}
                          datePickerProps={{
                            numberOfCalendars: 1,
                            maximumDate: new Date()
                          }}
                          preselected={this.dateRangePreselectedCustom}
                        />
                      );
                    }
                  }
                  `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="required">dateRange</td>
              <td>
                object <br/>
                <code>
                  {'{'}
                  start: Date || moment,
                  end: Date || moment,
                  singleDate: Date || moment
                  {'}'}
                </code>
              </td>
              <td>none</td>
              <td>Current value</td>
            </tr>
            <tr>
              <td className="required">singleDate</td>
              <td>
                <code>Date || moment</code>
              </td>
              <td>false</td>
              <td>Current value for singleDateMode</td>
            </tr>
            <tr>
              <td>singleDateMode</td>
              <td>
                boolean <br/>
                If true - one date selection, false - date range
              </td>
              <td>false</td>
              <td>Mode for date selection</td>
            </tr>
            <tr>
              <td>labels</td>
              <td>
                array <br/>
              </td>
              <td><code>['Start Date:', 'End Date:']</code></td>
              <td>Labels for dates input</td>
            </tr>
            <tr>
              <td>updateDateRange</td>
              <td>function(start, end, preselectedRange)</td>
              <td>Empty function</td>
              <td>Callback after range selected</td>
            </tr>
            <tr>
              <td>preselected</td>
              <td>
                boolean | array <br/>
                if true - default Week, Month, 3 Months <br/>
                array of objects <br/>
                <code>
                  {'{'} <br/>
                  key: string, <br/>
                  title: string, <br/>
                  start: Date | moment, <br/>
                  end: Date | moment <br/>
                  {'}'}
                </code>
              </td>
              <td>false</td>
              <td>Preselected date range. Available if singleDateMode = false</td>
            </tr>
            <tr>
              <td>insidePreselectors</td>
              <td>
                boolean<br/>
                ('preselected' should not be false and not be an empty array)
              </td>
              <td>false</td>
              <td>Move preselected buttons into calendar</td>
            </tr>
            <tr>
              <td>withTimePicker</td>
              <td>boolean</td>
              <td>false</td>
              <td>Add time picker. singleDateMode should be also true. For showing time use dateFormat="DD/MM/YYYY hh:mm
                A"
              </td>
            </tr>
            <tr>
              <td>updateByDone</td>
              <td>boolean</td>
              <td>true</td>
              <td>Update time after close the Picker</td>
            </tr>
            <tr>
              <td>datePickerProps</td>
              <td>object</td>
              <td>empty</td>
              <td>
                Available props from &nbsp;
                <a href="https://github.com/onefinestay/react-daterange-picker#available-props">here</a>
              </td>
            </tr>
            <tr>
              <td>tooltipParams</td>
              <td>object</td>
              <td>undefined</td>
              <td>
                Parameters for tooltip component
              </td>
            </tr>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>""</td>
              <td>Class name of bordered input element</td>
            </tr>
            <tr>
              <td>dateRangePickerClassName</td>
              <td>string</td>
              <td>""</td>
              <td>Class name for the whole component, including bordered input and opening calendar</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
