import React, { Component } from 'react';
import { CustomerSelect } from 'factor';

import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class CustomerSelectPage extends Component {
  constructor() {
    super();
    this.state = {
      value: null,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  options = [
    {
      label: 'FName 180 LName 180',
      value: '80',
      email: 'email_180@example.com',
      image: 'apple-touch-icon.png',
    },
    {
      label: 'FName 181 LName 181',
      value: '81',
      email: 'email_181@example.com',
      image: 'apple-touch-icon.png',
    },
    {
      label: 'FName 182 LName 182',
      value: '82',
      email: 'email_182@example.com',
      image: 'apple-touch-icon.png',
    },
  ];

  onChange = (value) => this.setState({value});

  render() {
    const {
      value,
    } = this.state;

    return (
      <PagePartial
        title="CustomerSelect"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">CustomerSelect</h4>
            <CustomerSelect
              options={this.options}
              value={value}
              onChange={this.onChange}
            />
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React, { Component } from 'react';
                import { CustomerSelect } from 'factor';

                export class CustomerSelectPage extends Component {
                  constructor() {
                    super();
                    this.state = {
                      value: null,
                    };
                  }

                  options = [
                    {
                      id: '80',
                      label: 'FName 180 LName 180',
                      email: 'email_180@example.com',
                      image: 'apple-touch-icon.png',
                      value: 'FName 180 LName 180',
                    },
                    {
                      id: '81',
                      label: 'FName 181 LName 181',
                      email: 'email_181@example.com',
                      image: 'apple-touch-icon.png',
                      value: 'FName 181 LName 181',
                    },
                    {
                      id: '82',
                      label: 'FName 182 LName 182',
                      email: 'email_182@example.com',
                      image: 'apple-touch-icon.png',
                      value: 'FName 182 LName 182',
                    },
                  ];

                  onChange = (value) => this.setState({value});

                  render() {
                    const {
                      value,
                    } = this.state;

                    return (
                      <CustomerSelect
                        options={this.options}
                        value={value}
                        onChange={this.onChange}
                      />
                    )
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">CustomerSelect Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>Custom css class</td>
              </tr>
              <tr>
                <td>options</td>
                <td>array</td>
                <td>undefined</td>
                <td>Options like for Select component</td>
              </tr>
              <tr>
                <td>value</td>
                <td>{'{'} label: string; value: string | number {'}'}</td>
                <td>undefined</td>
                <td>Selected option</td>
              </tr>
              <tr>
                <td>onChange</td>
                <td>(v: value) {'=>'} void</td>
                <td>undefined</td>
                <td>Callback after value selected</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
