import React, { Component } from 'react';
import { CollapsibleBlock } from 'factor';
import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class CollapsibleBlockPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  render() {
    return (
      <PagePartial
        title="Collapsible Block"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col">
            <h4 className="title-card mb-2">Collapsible Block</h4>
            <CollapsibleBlock collapsible header={{
              title: 'Block Title',
              summary: {},
            }}>
              <div>Some Content</div>
            </CollapsibleBlock>
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { CollapsibleBlock } from 'factor';

                class YourPage extends React.Component {
                  render() {
                    return (
                      <div className="row example">
                        <div className="col">
                          <h4 className="title-card mb-2">Collapsible Block</h4>
                          <CollapsibleBlock header={{
                            title: 'Block Title',
                            summary: {},
                          }}>
                            <div>Some Content</div>
                          </CollapsibleBlock>
                        </div>
                      </div>
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Block Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>custom css class</td>
              </tr>
              <tr>
                <td>children</td>
                <td>element</td>
                <td>none</td>
                <td>Block content</td>
              </tr>
              <tr>
                <td>isExtended</td>
                <td>boolean</td>
                <td>undefined | undefined</td>
                <td>Prop to extend the block from outside</td>
              </tr>
              <tr>
                <td>collapsible</td>
                <td>boolean | undefined</td>
                <td>undefined</td>
                <td>show the 'extend' arrow</td>
              </tr>
              <tr>
                <td>isCollapseOpen</td>
                <td>boolean | undefined</td>
                <td>undefined</td>
                <td>whether block is open or not (to handle from outside)</td>
              </tr>
              <tr>
                <td>header</td>
                <td>object:<br />
                  {'{'} <br />
                  <span className="required">title: string | element,</span> <br />
                  <span>summary: {'{'} [key: string ]: string, {'}'}</span> <br />
                  {'}'}</td>
                <td />
                <td>Config for header. Summary is shown when the block is collapsed</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
