import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { Snackbar, Button } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class SnackbarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      open2: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage ', link: '#react' },
  ];

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const {
      open,
    } = this.state;

    return (
      <PagePartial
        navigation={this.pageNavigation}
        title="Snackbar"
      >
        <h5 id="example">Example</h5>
        <div className="example row">
          <div className="col-6">
            <Button className="btn-square _shamrock" onClick={() => this.setState({
              open: true,
            })}>Show</Button>
          </div>
        </div>
        <Snackbar
          open={open}
          onClose={this.handleClose}
          message={'All changes saved!'}
          action={[
            <span key="undo" onClick={this.handleClose} className="text-color text-color_orange cursor cursor_pointer">UNDO</span>
          ]}
        />
        <PagePartialUsageBlock
          id="react"
          subTitle="(react only)"
        >
          <p>For using this Snackbar with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { Snackbar, Icon } from 'factor';
                import 'factor/src/scss/_snackbar.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      open: false,
                    };
                  }

                  render() {
                    const {
                      open,
                    } = this.state;

                    return (
                      <Snackbar
                        open={open}
                        onClose={() => this.setState({
                          open: false,
                        })}
                        message={'All changes saved!'}
                        action={[
                          <span key="undo">UNDO</span>
                        ]}
                      />
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">Params</h6>}
        >
          <table className="props-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>open</td>
              <td>boolean</td>
              <td>false</td>
              <td>Show snackbar</td>
            </tr>
            <tr>
              <td>onClose</td>
              <td>function</td>
              <td>none</td>
              <td>Close callback</td>
            </tr>
            <tr>
              <td>autoHideDuration</td>
              <td>number</td>
              <td>10000</td>
              <td>Time in ms before close</td>
            </tr>
            <tr>
              <td>message</td>
              <td>string | node</td>
              <td>none</td>
              <td>Snackbar content</td>
            </tr>
            <tr>
              <td>action</td>
              <td>array of nodes</td>
              <td>none</td>
              <td>Custom actions</td>
            </tr>
            <tr>
              <td>className</td>
              <td>string</td>
              <td>none</td>
              <td>Snackbar additional classes</td>
            </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
