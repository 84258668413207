import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

const HeaderBlock = () => (
  <h5 className="header-block__title">Usage</h5>
);

export class PagePartialUsageBlock extends PureComponent {
  render() {
    const {
      title = <HeaderBlock/>,
      subTitle,
      children,
      id,
    } = this.props;
    return (
      <Fragment>
        <div className="header-block" id={id}>
          {title}
          <h6 className="header-block__subtitle">{subTitle}</h6>
        </div>
        <div className="row">
          <div className="col-12">
            {children}
          </div>
        </div>
      </Fragment>
    );
  }
}

PagePartialUsageBlock.propTypes = {
  title: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  subTitle: PropTypes.string,
  id: PropTypes.string,
};
