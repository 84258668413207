import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { ButtonsGroup } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

export class ButtonsGroupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: 'fruits',
      fruit: 'cherry',
      checkboxes: ['apple', 'banana'],
      btnSize: null,
    };
  }

  pageNavigation = [
    {title: 'Example', link: '#example'},
    {title: 'React usage', link: '#react'},
    {title: 'Html usage', link: '#html'},
  ];

  items1 = [
    {title: 'Fruits', value: 'fruits'},
    {title: 'Vegetables', value: 'vegetables'},
    {title: 'All', value: 'all'},
  ];

  items2 = [
    {title: 'Apple', value: 'apple'},
    {title: 'Banana', value: 'banana'},
    {title: 'Cherry', value: 'cherry'},
    {title: 'Pear', value: 'pear'},
  ];

  items3 = [
    {title: 'Apple', value: 'apple'},
    {title: 'Banana', value: 'banana'},
    {title: 'Cherry', value: 'cherry'},
    {title: 'Pear', value: 'pear'},
  ];

  sizes = (function() {
    const sizes = [
      { title: 'Default', value: null },
      { title: 'Small', value: 'sm' },
      { title: 'Medium', value: 'md' },
    ];
    return sizes;
  })();

  renderSizeToggle() {
    return (
      <div className="col-6 d-flex justify-content-end">
        <ButtonsGroup
          value={this.state.btnSize}
          items={this.sizes}
          onChange={(v) => this.setState({
            btnSize: v,
          })}
        />
      </div>
    );
  }

  render() {
    const {
      category,
      fruit,
      checkboxes,
      btnSize,
    } = this.state;

    const btnGroup = (
      <ButtonsGroup className="radio-group_highlight" size={btnSize} items={this.items2} value={fruit} onChange={(v) => this.setState({fruit: v})}/>
    );

    return (
      <PagePartial
        title="Buttons group"
        navigation={this.pageNavigation}
      >
        <div className="row mb-2 mt-2 align-items-center">
          <h5 id="example" className="col">Example</h5>
          {this.renderSizeToggle()}
        </div>
        <div className="example row">
          <div className="col-6">
            <ButtonsGroup
                size={btnSize}
                items={this.items1}
                value={category}
                onChange={(v) => this.setState({category: v})}
            />
          </div>
          <div className="col-6">
            {btnGroup}
          </div>
        </div>
        <h5 id="example">Example with multiple active values</h5>
        <div className="example row">
          <div className="col-12">
            <ButtonsGroup
                size={btnSize}
                items={this.items3}
                value={checkboxes}
                onChange={(v) => {
                  const index = checkboxes.indexOf(v);
                  let newCheckboxes = checkboxes.slice();
                  if(index === -1) {
					  newCheckboxes.push(v);
				  } else {
					  newCheckboxes.splice(index, 1);
                  }
                  this.setState({checkboxes: newCheckboxes});
				}}
            />
          </div>
        </div>
        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using buttons group with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import { ButtonsGroup } from 'factor';
                import 'factor/src/scss/_buttons-group.scss';

                class YourPage extends React.Component {
                  constructor(props) {
                    super(props);

                    this.state = {
                      category: 'apple',
                    };
                  }

                  categories = [
                    {title: 'Apple', value: 'apple'},
                    {title: 'Banana', value: 'banana'},
                    {title: 'Cherry', value: 'cherry'},
                    {title: 'Pear', value: 'pear'},
                  ];

                  render() {
                    const {
                      category,
                    } = this.state;

                    return (
                      <ButtonsGroup
                        size="sm"
                        items={this.categories}
                        value={category}
                        onChange={(v) => this.setState({category: v})}
                      />
                    );
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br/>
        <PagePartialUsageBlock
          subTitle="(html only)"
          id="html"
        >
          <p>For using this buttons group you need to import <code className="inline red">buttons-group.scss</code> in your scss file.
          </p>
          <Highlight className="language-css">
            {
              `@import "~factor/src/scss/buttons-group";`
            }
          </Highlight>
          <p>Insert html</p>
          <Highlight>
            {btnGroup}
          </Highlight>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
