import React, { Component } from 'react';
import { ImageCard, Icon, Tumbler } from 'factor';

import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

import image from './imageCardPage/img/image-card-example.png';

const ACTIONS = [
  { label: 'View', iconName: 'Impressions' },
  { label: 'Edit', iconName: 'Edit' },
  { label: 'Approve', iconName: 'Done' },
  { label: 'Decline', iconName: 'NoData' },
];

const PARAMS = [
  { label: 'Ad Name:', value: 'Ad Name here' },
  { label: 'Click URL:', value: 'www.urlurl.com' },
  { label: 'Pixel URL:', value: 'www.urlurl.com' },
  { label: 'Parts Of: ', value: '6' },
];

const SUB_CONTENT_TEXT_FIELDS = [
  { label: 'Click Url', placeholder: 'Click Url' },
  { label: 'Pixel Url', placeholder: 'Pixel Url' },
];

export class ImageCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      isSubContentOpen: false,
      textFields: SUB_CONTENT_TEXT_FIELDS.map(textField => ({
        ...textField,
        value: '',
      })),
      isCompactView: false,
    };
  }

  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  actions = ACTIONS.map(action => {
    let onClick = () => console.log(action.label);
    switch (action.label) {
      case 'Edit':
        onClick = () => this.toggleSubContent();
        break;
      default:
    }
    return {
      ...action,
      onClick,
    }
  });

  getTextFields = () => {
    const { textFields } = this.state;
    return textFields.map(textField => ({
      ...textField,
      onChange: this.onSubContentTextFieldChange(textField.label),
    }));
  };

  onSubContentTextFieldChange = (label) => (value) => {
    const { textFields } = this.state;
    const textField = textFields.find(textField => textField.label === label);
    textField.value = value;
    this.setState({ textFields });
  }

  toggleCardSelection = () => {
    const { isSelected } = this.state;

    this.setState({ isSelected: !isSelected });
  };

  toggleSubContent = () => {
    const { isSubContentOpen } = this.state;
    this.setState({ isSubContentOpen: !isSubContentOpen });
  }

  toggleCompactView = () => {
    const { isCompactView } = this.state;
    this.setState({ isCompactView: !isCompactView });
  }

  render() {
    const { isSelected, isSubContentOpen, isCompactView } = this.state;

    return (
      <PagePartial
        title="Image Card"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="mt-3">
          <Tumbler
            title="Compact view"
            on={isCompactView}
            onChange={this.toggleCompactView}
          />
        </div>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">ImageCard</h4>
            <ImageCard
              title="Card Title"
              imageSrc={image}
              actions={this.actions}
              params={[
                ...PARAMS,
                { label: 'Size:', value: '300x250' },
              ]}
              isSelected={isSelected}
              onClick={this.toggleCardSelection}
              isSubContentOpen={isSubContentOpen}
              subContentTextFields={this.getTextFields()}
              toggleSubContent={this.toggleSubContent}
              isCompact={isCompactView}
            />
          </div>
          <div className="col-6">
            <h4 className="title-card mb-2">ImageCard without preview</h4>
            <ImageCard
              title="Card Title"
              imageSrc={null}
              actions={this.actions}
              params={[
                ...PARAMS,
                { label: 'Duration:', value: 55 },
              ]}
              onClick={() => {}}
              iconName="VoiceXS"
              noImageElement={<Icon name="NoData" />}
              clickedOnImage={() => console.log('Clicked on Image!')}
              isCompact={isCompactView}
            />
          </div>
        </div>

        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React from 'react';
                import { ImageCard } from 'factor';

                import image from './imageCardPage/img/image-card-example.png';

                const ACTIONS = [
                  { label: 'View', iconName: 'Impressions' },
                  { label: 'Edit', iconName: 'Edit' },
                  { label: 'Approve', iconName: 'Done' },
                  { label: 'Decline', iconName: 'NoData' },
                ];

                const PARAMS = [
                  { label: 'Ad Name:', value: 'Ad Name here' },
                  { label: 'Click URL:', value: 'www.urlurl.com' },
                  { label: 'Pixel URL:', value: 'www.urlurl.com' },
                  { label: 'Parts Of: ', value: '6' },
                ];

                export class ImageCardPage extends Component {
                  constructor(props) {
                    super(props);
                    this.state = {
                      isSelected: false,
                    };
                  }

                  actions = ACTIONS.map(action => ({
                    ...action,
                    onClick: () => console.log(action.label)
                  }));

                  toggleCardSelection = () => {
                    const { isSelected } = this.state;

                    this.setState({ isSelected: !isSelected });
                  };

                  render() {
                    const { isSelected } = this.state;

                    return (
                      <ImageCard
                        title="Card Title"
                        imageSrc={image}
                        actions={this.actions}
                        params={PARAMS}
                        isSelected={isSelected}
                        onClick={this.toggleCardSelection}
                      />
                    }
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">ImageCard Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>custom css class</td>
              </tr>
              <tr>
                <td>title</td>
                <td>string</td>
                <td>none</td>
                <td>card title</td>
              </tr>
              <tr>
                <td>imageSrc</td>
                <td>string</td>
                <td>none</td>
                <td>card image src</td>
              </tr>
              <tr>
                <td>actions</td>
                <td>
                  {'{'}
                  <div style={{ marginLeft: '1rem' }}>label: string; </div>
                  <div style={{ marginLeft: '1rem' }}>iconName: string; </div>
                  <div style={{ marginLeft: '1rem' }}>onClick: (...args: any[]) => void; </div>
                  {'}'}
                </td>
                <td>undefined</td>
                <td>list of buttons in menu with labels, icons and onClick actions</td>
              </tr>
              <tr>
                <td>params</td>
                <td>
                  {'{'}
                  <div style={{ marginLeft: '1rem' }}>label: string; </div>
                  <div style={{ marginLeft: '1rem' }}>value: string; </div>
                  {'}'}
                </td>
                <td>undefined</td>
                <td>list of parameters</td>
              </tr>
              <tr>
                <td>isSelected</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>if card is selected</td>
              </tr>
              <tr>
                <td>onClick</td>
                <td>function</td>
                <td>undefined</td>
                <td>card onClick action</td>
              </tr>
              <tr>
                <td>noImageElement</td>
                <td>React component or Node element</td>
                <td>undefined</td>
                <td>Component which should be rendered if item doesn't have image preview</td>
              </tr>
              <tr>
                <td>iconName</td>
                <td>string</td>
                <td>undefined</td>
                <td>Icon name (this name should be in the list of icons)</td>
              </tr>
              <tr>
                <td>loaingMessage</td>
                <td>string</td>
                <td>undefined</td>
                <td>Text on loading background</td>
              </tr>
              <tr>
                <td>clickedOnImage</td>
                <td>function</td>
                <td>undefined</td>
                <td>Image click callback</td>
              </tr>
              <tr>
                <td>subContent</td>
                <td>React Component</td>
                <td>undefined</td>
                <td>Card can be switched to this content</td>
              </tr>
              <tr>
                <td>isSubContentOpen</td>
                <td>boolean</td>
                <td>undefined</td>
                <td>If card is switched to subContent</td>
              </tr>
              <tr>
                <td>subContentTextFields</td>
                <td>
                  {`{`}<br />
                  &nbsp;&nbsp;label: string;<br />
                  &nbsp;&nbsp;placeholder?: string;<br />
                  &nbsp;&nbsp;value: string;<br />
                  &nbsp;&nbsp;onChange: () => void<br />
                  {`}`}
                </td>
                <td>undefined</td>
                <td>Array of TextFields to open in subContent</td>
              </tr>
              <tr>
                <td>toggleSubContent</td>
                <td>function</td>
                <td>undefined</td>
                <td>To pass close/open function to subContent component</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
