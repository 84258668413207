import React, { Component } from 'react';
import { PagePartial } from '../demoComponents/PagePartial';
import { ActionMenu } from 'factor';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

const ACTIONS = [
  { label: 'View', iconName: 'Impressions' },
  { label: 'Edit', iconName: 'Edit' },
  { label: 'Approve', iconName: 'Done' },
  { label: 'Decline', iconName: 'NoData' },
];

export class ActionMenuPage extends Component {
  pageNavigation = [
    { title: 'Example', link: '#example' },
    { title: 'React usage', link: '#react' },
  ];

  actions = ACTIONS.map(action => ({
    ...action,
    onClick: () => console.log(action.label),
  }));

  render() {
    return (
      <PagePartial
        title="Action Menu"
        navigation={this.pageNavigation}
      >
        <h5 id="example">Example</h5>
        <div className="row example">
          <div className="col-6">
            <h4 className="title-card mb-2">ActionMenu</h4>
            <ActionMenu
              actions={this.actions}
              close={() => console.log('close ActionMenu')}
            />
          </div>
        </div>

        <PagePartialUsageBlock
          subTitle="(react only)"
          id="react"
        >
          <p>For using this Card with React use this</p>
          <Highlight className="language-jsx" code="js">
            {
              `
                import React from 'react';
                import { ActionMenu } from 'factor';

                const ACTIONS = [
                  { label: 'View', iconName: 'Impressions' },
                  { label: 'Edit', iconName: 'Edit' },
                  { label: 'Approve', iconName: 'Done' },
                  { label: 'Decline', iconName: 'NoData' },
                ];

                export class ActionMenuPage extends Component {

                  actions = ACTIONS.map(action => ({
                    ...action,
                    onClick: () => console.log(action.label),
                  }));

                  render() {
                    return (
                      <ActionMenu
                        actions={this.actions}
                        close={() => console.log('close ActionMenu')}
                      />
                    )
                  }
                }
              `
            }
          </Highlight>
        </PagePartialUsageBlock>
        <br />
        <PagePartialUsageBlock
          title={<h6 className="header-block__title">ActionMenu Props</h6>}
        >
          <table className="props-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Default</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td>none</td>
                <td>custom css class</td>
              </tr>
              <tr>
                <td>actions</td>
                <td>
                  {'{'}
                  <div style={{ marginLeft: '1rem' }}>label: string; </div>
                  <div style={{ marginLeft: '1rem' }}>iconName: string; </div>
                  <div style={{ marginLeft: '1rem' }}>onClick: (...args: any[]) => void; </div>
                  {'}'}
                </td>
                <td>undefined</td>
                <td>list of buttons in menu with labels, icons and onClick actions</td>
              </tr>
              <tr>
                <td>close</td>
                <td>function</td>
                <td>undefined</td>
                <td>When set 'More' icon is showed and has the set function as onClick</td>
              </tr>
            </tbody>
          </table>
        </PagePartialUsageBlock>
      </PagePartial>
    );
  }
}
