import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, SidebarHeader, RightbarTabs, RightbarTab } from 'factor';

import styles from './layoutPage/layoutPage.module.scss';
import { useComponentsLayout } from '../store/actions';
import { Drawer } from '../demoComponents/Drawer';
import { PagePartial } from '../demoComponents/PagePartial';
import { PagePartialUsageBlock } from '../demoComponents/PagePartialUsageBlock';
import { Highlight } from '../demoComponents/Highlight';

class LayoutPageComponent extends Component {
  state = {
    opened: true,
    isRightbarOpen: true,
    activeRightbarLabel: null,
  };

  componentDidMount() {
    this.props.useComponentsLayout(false);
  }

  componentWillUnmount() {
    this.props.useComponentsLayout(true);
  }

  pageNavigation = [
    { title: 'React usage', link: '#react' },
  ];

  setActiveTab = (newActiveLabel) => {
    const { activeRightbarLabel: oldActiveLabel } = this.state;

    let activeLabel = newActiveLabel;

    if (newActiveLabel === oldActiveLabel) {
      activeLabel = null;
    }

    this.setState({ activeRightbarLabel: activeLabel });
  };

  toggleRightbar = () => {
    const { isRightbarOpen, activeRightbarLabel } = this.state;

    this.setState({
      isRightbarOpen: !isRightbarOpen,
      activeRightbarLabel: isRightbarOpen ? null : activeRightbarLabel,
    });
  };

  render() {
    const { activeRightbarLabel } = this.state;
    return (
      <Layout
        sidebarClassName={styles.sidebar}
        opened={this.state.opened}
        toggleSidebar={(opened) => this.setState({
          opened: typeof opened === 'boolean' ? opened : !this.state.opened
        })}
        sidebar={<>
          <SidebarHeader
            title="Sidebar Header"
            onCloseClick={() => alert('onCloseClick')}
          />
          <Drawer static />
        </>}
        isRightbarExpanded={activeRightbarLabel !== null}
        isRightbarOpen={this.state.isRightbarOpen}
        toggleRightbar={this.toggleRightbar}
        rightbarChildren={
          <RightbarTabs
            activeLabel={activeRightbarLabel}
            setActiveTab={this.setActiveTab}
          >
            <RightbarTab label="Globe" iconName="Globe">
              <SidebarHeader title="Globe" onCloseClick={() => this.setActiveTab(null)} />
              Globe
            </RightbarTab>
            <RightbarTab label="Upload" iconName="Upload">
              <SidebarHeader title="Upload" onCloseClick={() => this.setActiveTab(null)} />
              Upload
            </RightbarTab>
          </RightbarTabs>
        }
      >
        <PagePartial
          navigation={this.pageNavigation}
          title="Layout"
          className="page-layout"
        >
          <PagePartialUsageBlock
            id="react"
            subTitle="(react only)"
          >
            <p>For using this Layout with React use this</p>
            <Highlight className="language-jsx" code="js">
              {
                `
                import React, { Component } from 'react';
                import { Layout, RightbarTabs, RightbarTab, Icon } from 'factor';

                class LayoutPageComponent extends Component {
                  state = {
                    opened: true,
                    isRightbarOpen: true,
                    activeRightbarLabel: 'Globe',
                  };

                  setActiveTab = (newActiveLabel) => {
                    const { activeRightbarLabel: oldActiveLabel } = this.state;

                    let activeLabel = newActiveLabel;

                    if (newActiveLabel === oldActiveLabel) {
                      activeLabel = null;
                    }

                    this.setState({ activeRightbarLabel: activeLabel });
                  };

                  toggleRightbar = () => {
                    const { isRightbarOpen, activeRightbarLabel } = this.state;

                    this.setState({
                      isRightbarOpen: !isRightbarOpen,
                      activeRightbarLabel: isRightbarOpen ? null : activeRightbarLabel,
                    });
                  };

                  render() {
                    const { opened, activeRightbarLabel } = this.state;
                    return (
                      <Layout
                        opened={opened}
                        toggleSidebar={(opened) => this.setState({
                          opened: typeof opened === 'boolean' ? opened : !this.state.opened
                        })}
                        sidebar={<Drawer static />}
                        isRightbarExpanded={activeRightbarLabel !== null}
                        isRightbarOpen={this.state.isRightbarOpen}
                        toggleRightbar={this.toggleRightbar}
                        rightbarChildren={
                          <RightbarTabs
                            activeLabel={activeRightbarLabel}
                            setActiveTab={this.setActiveTab}
                          >
                            <RightbarTab label="Globe" iconName="Globe">
                              Globe
                            </RightbarTab>
                            <RightbarTab label="Upload" iconName="Upload">
                              Upload
                            </RightbarTab>
                          </RightbarTabs>
                        }
                      >
                        Main Content
                      </Layout>
                    )
                  }
                }
              `
              }
            </Highlight>
          </PagePartialUsageBlock>
          <br />
          <PagePartialUsageBlock
            title={<h6 className="header-block__title">Layout Params</h6>}
          >
            <table className="props-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Default</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>className</td>
                  <td>string</td>
                  <td>none</td>
                  <td>Layout css custom classname</td>
                </tr>
                <tr>
                  <td>mainClassName</td>
                  <td>string</td>
                  <td>none</td>
                  <td>Layout main block css custom classname</td>
                </tr>
                <tr>
                  <td>opened</td>
                  <td>boolean</td>
                  <td>none</td>
                  <td>Sidebar open/closed state</td>
                </tr>
                <tr>
                  <td>toggleSidebar</td>
                  <td>function</td>
                  <td>none</td>
                  <td>Changes sidebar open/close state</td>
                </tr>
                <tr>
                  <td>sidebar</td>
                  <td>element</td>
                  <td>none</td>
                  <td>Sidebar content</td>
                </tr>
                <tr>
                  <td>isRightbarOpen</td>
                  <td>boolean</td>
                  <td>true</td>
                  <td>Rightbar open/closed state</td>
                </tr>
                <tr>
                  <td>isRightbarExpanded</td>
                  <td>boolean</td>
                  <td>false</td>
                  <td>If Rightbar width is expanded</td>
                </tr>
                <tr>
                  <td>toggleRightbar</td>
                  <td>function</td>
                  <td>undefined</td>
                  <td>Open/close rightbar</td>
                </tr>
                <tr>
                  <td>rightbarChildren</td>
                  <td>element</td>
                  <td>undefined</td>
                  <td>Rightbar content</td>
                </tr>
              </tbody>
            </table>
          </PagePartialUsageBlock>
          <br />
          <PagePartialUsageBlock
            title={<h6 className="header-block__title">RighbarTabs Params</h6>}
          >
            <table className="props-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Default</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>activeLabel</td>
                  <td>string</td>
                  <td>undefined</td>
                  <td>Label of active RightbarTab</td>
                </tr>
                <tr>
                  <td>setActiveTab</td>
                  <td>(activeLabel: string) => void</td>
                  <td>undefined</td>
                  <td>Rightbar button onClick function with clicked button label passed as argument</td>
                </tr>
              </tbody>
            </table>
          </PagePartialUsageBlock>
          <br />
          <PagePartialUsageBlock
            title={<h6 className="header-block__title">RighbarTab Params</h6>}
          >
            <table className="props-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Default</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>label</td>
                  <td>string</td>
                  <td>undefined</td>
                  <td>RightbarTab unique label</td>
                </tr>
                <tr>
                  <td>iconName</td>
                  <td>string</td>
                  <td>undefined</td>
                  <td>Rightbar ButtonCircle icon name</td>
                </tr>
                <tr>
                  <td>iconClassName</td>
                  <td>string</td>
                  <td>undefined</td>
                  <td>Rightbar ButtonCircle custom className</td>
                </tr>
                <tr>
                  <td>iconChildren</td>
                  <td>React Component</td>
                  <td>undefined</td>
                  <td>Rightbar ButtonCircle children</td>
                </tr>
              </tbody>
            </table>
          </PagePartialUsageBlock>
        </PagePartial>
      </Layout>
    );
  }
}

const LayoutPage = connect(
  state => ({
    isComponentsLayout: state.isComponentsLayout,
  }),
  dispatch => useComponentsLayout(dispatch),
)(LayoutPageComponent);

export {LayoutPage};

LayoutPageComponent.propTypes = {
  useComponentsLayout: PropTypes.func,
};
